import React from "react";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import {
  List,
  ListItem,
  Divider,
  Hidden,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  Grid,
  LinearProgress
} from "@material-ui/core";

import { NoStyleLink } from "../../../components/sharedStyledComponents";
import { IDeviceAlert } from "../models/IAlerts";
import { ITableDeleteCell } from "../../../core/models/IShared";
import { IDevice } from "../../models/IDevice";
import { getDeviceAlertParameterName } from "../services/DeviceAlertService";
import { IPaginationView } from "../../../core/models/MixinInterfaces";
import { Pagination } from "../../../components/Pagination";

interface IAlertListProps {
  isLoading?: boolean;
  rows: IDeviceAlert[];
  totalItems: number;
  onChangePage: (page: string) => void;
  pagination: IPaginationView;
  handleDialogOpen: (
    itemInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
  device: IDevice;
}

export const getPrimaryText = (
  { parameter: { label }, message }: IDeviceAlert,
  { basicCalculationProperties }: IDevice
) =>
  `${getDeviceAlertParameterName(
    basicCalculationProperties,
    label
  )}: "${message}"`;

export const getSecondaryText = ({ alertLevel, threshold }: IDeviceAlert) =>
  `${alertLevel}: ${threshold}`;

export const AlertList: React.FunctionComponent<IAlertListProps> = ({
  isLoading,
  rows,
  device,
  handleDialogOpen,
  onChangePage,
  pagination,
  totalItems
}: IAlertListProps) => {
  return (
    <List>
      {isLoading && <LinearProgress />}
      {rows.map(row => (
        <React.Fragment key={row.id}>
          <Hidden smUp={true}>
            <Grid container={true} alignItems="center">
              <Grid item={true} xs={10}>
                <NoStyleLink
                  to={`/devices/${row.device}/alerts/${row.shortId}`}
                >
                  <ListItem>
                    <ListItemText
                      primary={getPrimaryText(row, device)}
                      secondary={getSecondaryText(row)}
                    />
                  </ListItem>
                </NoStyleLink>
              </Grid>
              <Grid item={true} xs={2}>
                <IconButton
                  color="primary"
                  onClick={handleDialogOpen({
                    visible: true,
                    id: row.id,
                    title: getPrimaryText(row, device)
                  })}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </Hidden>
          <Hidden xsDown={true}>
            <ListItem>
              <ListItemText
                primary={getPrimaryText(row, device)}
                secondary={getSecondaryText(row)}
              />
              <ListItemSecondaryAction>
                <NoStyleLink
                  to={`/devices/${row.device}/alerts/${row.shortId}`}
                >
                  <IconButton aria-label="Edit">
                    <EditIcon />
                  </IconButton>
                </NoStyleLink>
                <IconButton
                  color="primary"
                  onClick={handleDialogOpen({
                    visible: true,
                    id: row.id,
                    title: getPrimaryText(row, device)
                  })}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Hidden>
        </React.Fragment>
      ))}
      <Pagination
        isLoading={isLoading}
        onChangePage={onChangePage}
        pagination={pagination}
        totalItems={totalItems}
      />
    </List>
  );
};
AlertList.displayName = "AlertList";
