import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Grid } from "@material-ui/core";
import { Formik, FormikHelpers as FormikActions } from "formik";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { IUserProfile } from "./models/IUserProfile";
import { getUserById, updateUserById } from "./actions/userProfileAction";
import { EditUserProfileForm } from "./components/EditUserProfileForm";
import {
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import { withSnackbar, WithSnackbarProps } from "notistack";
import {
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError,
  snackbarMessages
} from "../core/utilities/SnackbarUtilities";

interface IPathParamsType {
  id: string;
}

interface IUserStateProps {
  profile: IUserProfile;
}

interface IUserDispatchProps {
  getUser: (id: string) => SnackbarError | void;
  updateUser: (profile: IUserProfile) => Promise<IUserProfile | undefined>;
}

type EditUserProps = IUserStateProps &
  IUserDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditUserProfile extends React.Component<EditUserProps> {
  public async componentDidMount() {
    const {
      getUser,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    const user = await getUser(id);
    if (isSnackbarError(user)) {
      enqueueSnackbar(user.message, user.options);
    }
  }

  public onSubmit = async (
    values: IUserProfile,
    { setSubmitting }: FormikActions<IUserProfile>
  ) => {
    const { updateUser, enqueueSnackbar } = this.props;
    if (values) {
      const user = await updateUser(values);
      if (isSnackbarError(user)) {
        enqueueSnackbar(user.message, user.options);
      } else {
        enqueueSnackbar(
          snackbarMessages.PROFILE_UPDATE_SUCCESS,
          getStyledSnackbarOptions("success")
        );
      }
      setSubmitting(false);
    }
  };

  public render() {
    const { profile } = this.props;
    return (
      <React.Fragment>
        <StyledTitle>My Profile</StyledTitle>
        <StyledBackground>
          <Grid item={true} xs={12} sm={6} md={4} lg={3} xl={3}>
            <Formik
              enableReinitialize={true}
              initialValues={profile}
              onSubmit={this.onSubmit}
            >
              {props => <EditUserProfileForm {...props} />}
            </Formik>
          </Grid>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IUserStateProps => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any): IUserDispatchProps => {
  return {
    getUser: (userId: string) => {
      dispatch(getUserById(userId));
    },
    updateUser: (profile: IUserProfile): Promise<IUserProfile | undefined> => {
      return dispatch(updateUserById(profile));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditUserProfile)));
