import axios, { AxiosResponse } from "axios";

import {
  Configuration,
  edlaApiHeaders,
  ediApiHeaders
} from "../../../core/configuration/config";
import { ActionType } from "../../../core/ActionTypes";
import {
  deviceDownlinkToApi,
  deviceDownlinkFromApi
} from "../services/DeviceDownlinksService";
import { Thunk } from "../../../core/store";
import { deviceDownlinkReducerType } from "../reducers/downlinkReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage,
  getShortId
} from "../../../core/utilities/ServiceUtilities";
import {
  checkError,
  SnackbarError
} from "../../../core/utilities/SnackbarUtilities";
import {
  IDeviceDownlink,
  initialDeviceDownlink,
  IDeviceDownlinkApi,
  ISeparatedDownlinkFormat
} from "../models/IDownlinks";
import { deviceReducerActionTypes } from "../../reducers/deviceReducer";
import { IDevice, IDeviceApi } from "../../models/IDevice";
import { deviceFromApi } from "../../services/DeviceService";
import { IDeviceModelApi } from "../../models/IDeviceModels";

export const createDownlinkByDeviceId: Thunk<deviceDownlinkReducerType> = (
  id: string,
  deviceDownlink: IDeviceDownlink
) => {
  return async (dispatch, _, opt): Promise<IDeviceDownlink | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SET_DEVICE_DOWNLINK_FORM_VALUE,
      payload: deviceDownlink
    });
    blockableDispatch({
      type: ActionType.DEVICE_DOWNLINK_LOADING,
      payload: true
    });

    try {
      const response: AxiosResponse<IDeviceDownlinkApi> = await axios.post(
        Configuration.EdlaAPIUrl + "/downlinks",
        deviceDownlinkToApi(deviceDownlink),
        edlaApiHeaders
      );

      return deviceDownlinkFromApi(response.data);
    } catch (err) {
      createErrorConsoleMessage(err, "createDownlinkByDeviceId", {
        id,
        deviceDownlink
      });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_DOWNLINK_LOADING,
        payload: false
      });
    }
  };
};

export const setDeviceOfDownlink: Thunk<
  deviceDownlinkReducerType | deviceReducerActionTypes
> = (id: string) => {
  return async (dispatch, _, opt): Promise<IDevice | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_DOWNLINK_LOADING,
      payload: true
    });

    const deviceDownlink = { ...initialDeviceDownlink };
    deviceDownlink.network.name = "Ellenex UDP";
    deviceDownlink.network.deviceNetworkId = "";
    deviceDownlink.network.downlinkConfig = {
      defaultPort: 5,
      apiToken: "",
      defaultConfirmed: true,
      url: ""
    };
    try {
      const { data: deviceResponse } = await axios.get<IDeviceApi>(
        Configuration.EdiAPIUrl + "/devices/" + id,
        ediApiHeaders
      );
      try {
        const { data: deviceModelResponse } = await axios.get<IDeviceModelApi>(
          Configuration.EdiAPIUrl +
            "/devicemodels/" +
            getShortId(deviceResponse.deviceModel),
          ediApiHeaders
        );
        if (deviceModelResponse) {
          deviceDownlink.deviceModel = deviceModelResponse;
        }
      } catch (err) {
        createErrorConsoleMessage(
          err,
          "setDeviceOfDownlink::fetchDeviceModel",
          {
            deviceModelId: deviceResponse.deviceModel
          }
        );
      }
      const device = deviceFromApi(deviceResponse);
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: device
      });

      blockableDispatch({
        type: ActionType.GET_EMPTY_DEVICE_DOWNLINK,
        payload: {
          ...deviceDownlink,
          device
        }
      });

      return device;
    } catch (err) {
      createErrorConsoleMessage(err, "setDeviceOfDownlink", {
        id
      });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_DOWNLINK_LOADING,
        payload: false
      });
    }
  };
};

export const setDeviceDownlinkFormatInitial = (
  downlink: IDeviceDownlink,
  formats: ISeparatedDownlinkFormat[]
): deviceDownlinkReducerType => {
  return {
    type: ActionType.SET_DEVICE_OF_DOWNLINK_FORMAT,
    payload: {
      ...downlink
    }
  };
};

export const clearDeviceDownlink = (): deviceDownlinkReducerType => {
  return {
    type: ActionType.CLEAR_DEVICE_DOWNLINK
  };
};

export const deleteDownlinkById: Thunk<deviceDownlinkReducerType> = (
  downlink: IDeviceDownlinkApi
) => {
  return async (dispatch, _, opt): Promise<void | SnackbarError> => {
    try {
      await axios.put(
        Configuration.APIBaseURL + downlink["@id"],
        downlink,
        edlaApiHeaders
      );
      return;
    } catch (err) {
      createErrorConsoleMessage(err, "deleteDownlinkById", {
        downlink
      });
      return checkError(err);
    }
  };
};
