import {
  IDeviceDownlink,
  IDeviceDownlinkApi,
  IDeviceDownlinkCollectionApi,
  IDeviceDownlinkCollection,
  initialDeviceDownlink,
  initialDeviceDownlinkCollection,
  initialNetworkDownlinkConfig,
  ISeparatedDownlinkFormat
} from "../models/IDownlinks";
import { getShortId } from "../../../core/utilities/ServiceUtilities";
import {
  IDeviceCollection,
  IDevice,
  initialDevice
} from "../../models/IDevice";
import {
  parseDownlinkFormat,
  TypedValueToHex
} from "../../../core/utilities/HexConverstion";

export const deviceDownlinkFromApi = (
  downlink: IDeviceDownlinkApi
): IDeviceDownlink => {
  const result: IDeviceDownlink = {
    ...initialDeviceDownlink,
    network: {
      appID: "",
      deviceNetworkId: "",
      name: "",
      networkType: "",
      downlinkConfig: {
        ...initialNetworkDownlinkConfig
      }
    }
  };

  result.context = downlink["@context"];
  result.type = downlink["@type"];
  result.id = downlink["@id"];
  result.device = {
    ...initialDevice,
    id: downlink.device,
    shortId: getShortId(downlink.device)
  };
  result.data = downlink.data || "";
  result.status = downlink.status || "";
  result.confirmed = downlink.confirmed || false;
  result.network.deviceNetworkId = downlink.network.deviceNetworkId || "";
  result.network.name = downlink.network.name || "";
  result.dateCreated = downlink.dateCreated || "";
  result.dateUpdated = downlink.dateUpdated || "";

  return result;
};

export const deviceDownlinkToApi = ({
  id,
  tmpHexs,
  device,
  status,
  dateCreated,
  dateUpdated,
  advanced
}: IDeviceDownlink): IDeviceDownlinkApi => ({
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Downlink",
  "@id": id,
  device: device.id,
  network: {
    name: "ELLENEX-UDP",
    deviceNetworkId: device.serial,
    ellenexUDP: {
      downlinkConfig: {
        defaultPort: 5,
        defaultConfirmed: true
      }
    }
  },
  data: {
    hex: tmpHexs
      .map(({ value, format, initialValue }) => {
        if (!advanced) {
          if (!initialValue) {
            return TypedValueToHex(format, value);
          }
          return value ? value : initialValue;
        }
        return value;
      })
      .join("")
      .trim()
      .toUpperCase()
  },
  status,
  confirmed: true,
  port: 5,
  dateCreated,
  dateUpdated
});

export const deviceDownlinkCollectionFromApi = (
  collection: IDeviceDownlinkCollectionApi
): IDeviceDownlinkCollection => ({
  ...initialDeviceDownlinkCollection,
  context: collection["@context"],
  type: collection["@type"],
  id: collection["@id"],
  members: collection.member.map(downlink => deviceDownlinkFromApi(downlink)),
  shortId: getShortId(collection["@id"]),
  totalItems: collection.totalItems,
  view: collection.view,
  __initialRequest: collection
});

export const getDeviceOfDownlink = (
  deviceId: string,
  deviceCollection: IDeviceCollection
): IDevice =>
  deviceCollection.members
    .filter(({ shortId }) => shortId === deviceId)
    .pop() || { ...initialDevice };

export const generateDownlinkDataMask = (
  downlinkFormat: string
): ISeparatedDownlinkFormat[] => {
  const separatedformats: ISeparatedDownlinkFormat[] = [];

  downlinkFormat.split(" ").forEach(format => {
    const [formatField, type, value] = format.split(":");
    const { hexSymbols } = parseDownlinkFormat(type);
    const mask = new Array(hexSymbols).fill(/[0-9,a-f,A-F]/);

    separatedformats.push({
      formatField,
      mask,
      format: type,
      initialValue: TypedValueToHex(type, value),
      hexLength: hexSymbols
    });
  });

  return separatedformats;
};

export const checkDeviceNetwork = (network: string): boolean => {
  switch (network) {
    case "LORIOT":
      return true;
    case "TTN":
      return true;
    case "SIGFOX":
      return true;
    case "ELLENEX-UDP":
      return true;
    default:
      return false;
  }
};

export const getDeviceNetworkType = (network: string): string => {
  switch (network) {
    case "LORIOT":
      return "lorawan";
    case "TTN":
      return "lorawan";
    case "SIGFOX":
      return "sigfox";
    case "ELLENEX-UDP":
      return "ellenexUDP";
    default:
      return "";
  }
};
