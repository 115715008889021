import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import {
  Grid,
  Button,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanel,
  TextField
} from "@material-ui/core";
import {
  AddAlert,
  BarChart as ChartIcon,
  ExpandMore,
  List
} from "@material-ui/icons";
import { Formik } from "formik";
import { FormikHelpers as FormikActions } from "formik/dist/types";

import { theme, withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { IDevice } from "./models/IDevice";
import {
  clearDevice,
  getDeviceByIdWithModel,
  updateDevice
} from "./actions/deviceAction";
import { EditDeviceForm } from "./components/EditDeviceForm";
import {
  NoStyleLink,
  StyledBackground,
  StyledTitle,
  StyledTitleButtonGrid
} from "../components/sharedStyledComponents";
import { DeviceMap } from "../Location/components/DeviceMap";
import { DeviceParameters } from "./components/DeviceParameters";
import { getResources } from "../actions/globalStateAction";
import { Configuration } from "../core/configuration/config";
import { IResourceLink } from "../models/IGlobalState";
import {
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError,
  snackbarMessages
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";

export const StyledGPSPanel = styled(ExpansionPanel)`
  margin-bottom: 15px;
`;
StyledGPSPanel.displayName = "StyledGPSPanel";

export const StyledAlertIcon = styled(AddAlert)`
  && {
    margin-right: ${theme.spacing(1)}px;
    font-size: 20px;
    color: ${Configuration.colors.icon.danger};
  }
`;
StyledAlertIcon.displayName = "StyledAlertIcon";

export const StyledListIcon = styled(List)`
  && {
    margin-right: ${theme.spacing(1)}px;
  }
`;
StyledListIcon.displayName = "StyledListIcon";

interface IPathParamsType {
  id: string;
}

interface IDevicesStateProps {
  device: IDevice;
}

interface IDevicesDispatchProps {
  refreshResources: () => Promise<IResourceLink[] | SnackbarError>;
  getDeviceById: (id: string) => Promise<IDevice | SnackbarError>;
  updateDeviceById: (
    id: string,
    device: IDevice
  ) => Promise<IDevice | SnackbarError>;
  clearCurrentDevice: () => void;
}

type EditDeviceProps = IDevicesStateProps &
  IDevicesDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditDevice extends React.Component<EditDeviceProps> {
  public async componentDidMount() {
    const {
      getDeviceById,
      clearCurrentDevice,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;

    clearCurrentDevice();
    const device = await getDeviceById(id);
    if (isSnackbarError(device)) {
      enqueueSnackbar(device.message, device.options);
    }
  }

  public componentWillUnmount() {
    this.props.clearCurrentDevice();
  }

  public onSubmit = async (
    values: IDevice,
    { setSubmitting }: FormikActions<IDevice>
  ) => {
    const {
      updateDeviceById,
      history,
      refreshResources,
      enqueueSnackbar
    } = this.props;
    if (values) {
      const updatedDevice = await updateDeviceById(
        values.shortId as string,
        values
      );
      if (isSnackbarError(updatedDevice)) {
        enqueueSnackbar(updatedDevice.message, updatedDevice.options);
      } else {
        history.goBack();
        enqueueSnackbar(
          snackbarMessages.DEVICE_UPDATE_SUCCESS,
          getStyledSnackbarOptions("success")
        );
      }
      setSubmitting(false);
      const resources = await refreshResources();
      if (isSnackbarError(resources)) {
        enqueueSnackbar(resources.message, resources.options);
      }
    }
  };

  public render() {
    const { device, match } = this.props;
    const deviceLoaded = device?.id.length > 0;
    return (
      <React.Fragment>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <StyledTitleButtonGrid
            container={true}
            item={true}
            justify="flex-end"
            alignItems="center"
          >
            <StyledTitleButtonGrid item={true}>
              {deviceLoaded ? device.name : "Loading..."}
            </StyledTitleButtonGrid>
            <NoStyleLink to="/devices">
              <Button color="default">
                <StyledListIcon />
                List
              </Button>
            </NoStyleLink>
          </StyledTitleButtonGrid>
        </StyledTitle>
        <StyledBackground>
          <Grid container={true} item={true} xs={12} justify="flex-end">
            <NoStyleLink to={`${match.params.id}/alerts`}>
              <Button color="default">
                <StyledAlertIcon />
                Alerts
              </Button>
            </NoStyleLink>
            <NoStyleLink to={`${match.params.id}/graph`}>
              <Button color="default">
                <ChartIcon />
                Graph
              </Button>
            </NoStyleLink>
          </Grid>
          <Formik
            enableReinitialize={true}
            initialValues={device}
            onSubmit={this.onSubmit}
          >
            {props => <EditDeviceForm {...props} />}
          </Formik>
        </StyledBackground>
        <StyledGPSPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>GPS Location</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container={true} direction="column">
              <TextField
                disabled={true}
                label="Latitude"
                value={
                  device.autoLocation ? device.autoLocation.geo.latitude : ""
                }
              />
              <br/>
              <TextField
                label="Longitude"
                value={
                  device.autoLocation ? device.autoLocation.geo.longitude : ""
                }
                disabled={true}
              />
            </Grid>
          </ExpansionPanelDetails>
        </StyledGPSPanel>
        <StyledBackground padding="0">
          <DeviceMap device={device} mapType="satellite" />
        </StyledBackground>
        <StyledTitle>DEVICE PARAMETERS</StyledTitle>
        <StyledBackground>
          <Formik
            enableReinitialize={true}
            initialValues={device}
            onSubmit={this.onSubmit}
          >
            {props => <DeviceParameters {...props} />}
          </Formik>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    refreshResources: () => dispatch(getResources()),
    getDeviceById: (id: string) => dispatch(getDeviceByIdWithModel(id)),
    updateDeviceById: (id: string, device: IDevice) =>
      dispatch(updateDevice(id, device)),
    clearCurrentDevice: () => dispatch(clearDevice())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditDevice)));
