import styled from "styled-components";
import { Link } from "react-router-dom";
import { Paper, Toolbar, Grid, Card } from "@material-ui/core";
import { Configuration } from "../core/configuration/config";

export const StyledToolbarIndent = styled(Toolbar)`
  visibility: hidden;
  pointer-events: none;
`;
StyledToolbarIndent.displayName = "StyledToolbarIndent";

export const NoStyleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;
NoStyleLink.displayName = "NoStyleLink";

interface IStyledBackground {
  padding?: string;
  overflow?: string;
}
export const StyledBackground = styled(Paper)<IStyledBackground>`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "25px 15px")};
  margin-bottom: 15px;
  overflow: ${props => (props.overflow ? props.overflow : "")};
`;
StyledBackground.displayName = "StyledBackground";

export const StyledTitle = styled(Grid)`
  &&& {
    color: #6f6f6f;
    padding: 40px 15px 20px;
    font-size: 15px;
    text-transform: uppercase;
  }
`;
StyledTitle.displayName = "StyledTitle";

export const StyledTitleButtonGrid = styled(Grid)`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
`;
StyledTitleButtonGrid.displayName = "StyledTitleButtonGrid";

interface IFullHeightBackground {
  minheight?: number;
}
export const FullHeightBackground = styled(Paper)<IFullHeightBackground>`
  width: 100%;
  ${Configuration.isMobile
    ? "height: calc(100vh - 250px)"
    : "height: calc(100vh - 195px); margin: 20px 0;"};
  min-height: 650px;
  overflow-y: hidden;
  ${props => (props.minheight ? "min-height:" + props.minheight + "px" : "")};
`;
FullHeightBackground.displayName = "FullHeightBackground";

export const StyledCard = styled(Card)`
  && {
    border: 2px solid aaa;
    border-radius: 0px;
  }

  && > div {
    padding-bottom: 8px;
  }
`;
StyledCard.displayName = "StyledCard";
