import moment from "moment";
import { Moment } from "moment";

import { ILoadable } from "../../../core/models/MixinInterfaces";
import { Configuration } from "../../../core/configuration/config";

export interface IParameterUnits {
  [parameter: string]: { unitCode: string; unitText: string };
}

export interface IViewDeviceParameters extends ILoadable {
  chartOverload: boolean;
  device: string;
  from: Moment;
  to: Moment;
  showHours: boolean;
  parameter: string;
  parameterList: string[];
  panelExpanded: boolean;
  aggregationEnabled: boolean;
  aggregationInterval: string;
  aggregationIntervalList: IAggregationInterval[];
  aggregationFunction: string[];
  aggregationFunctionList: IAggregationFunction[];
  chart: IDeviceParameterChartItem[];
  parameterUnits: IParameterUnits;
}

export interface IDeviceParameterChartItem {
  value?: number;
  min?: number;
  max?: number;
  median?: number;
  average?: number;
  integral?: number;
  deviation?: number;
  sum?: number;
  difference?: number;
  unit: string;
  time: string;
  ts?: number;
  delta?: number;
}
export interface IAggregationFunction {
  name: string;
  value: string;
}
export interface IAggregationInterval {
  name: string;
  value: string;
  format: string;
}
export const initialViewDeviceParameters: IViewDeviceParameters = {
  isLoading: true,
  device: "",
  panelExpanded: false,
  chart: [],
  chartOverload: false,
  from: moment().startOf("day"),
  to: moment()
    .startOf("day")
    .add(1, "days"),
  showHours: false,
  parameter: "",
  parameterList: [],
  aggregationEnabled: false,
  aggregationFunction: [Configuration.devices.graph.functions[0].value],
  aggregationFunctionList: Configuration.devices.graph.functions,
  aggregationInterval: Configuration.devices.graph.intervals[0].name,
  aggregationIntervalList: Configuration.devices.graph.intervals,
  parameterUnits: {}
};
