import moment from "moment";
import { Moment } from "moment";

import { IDevice, initialDevice } from "../../Devices/models/IDevice";
import { ILoadable } from "../../core/models/MixinInterfaces";

export interface IExportDevice extends ILoadable {
  deviceList: IDevice[];
  formatList: string[];
  from: Moment;
  to: Moment;
  fileFormat: string;
  device: IDevice;
}

export const initialExportDevice: IExportDevice = {
  device: { ...initialDevice },
  deviceList: [],
  fileFormat: "csv",
  formatList: ["csv"],
  from: moment()
    .startOf("day")
    .subtract(1, "day"),
  isLoading: true,
  to: moment().endOf("day")
};
