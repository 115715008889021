import React from "react";
import styled from "styled-components";
import { theme } from "../core/withThemeProvider";
import { volumeFillPercent } from "../core/utilities/ServiceUtilities";

export interface IVolumeIcon {
  percent?: number;
}

export const VolumeIcon: React.FunctionComponent<IVolumeIcon> = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <linearGradient id="linear-gradient" gradientTransform="rotate(90)">
      <stop offset={volumeFillPercent(props.percent)} stopColor="#fff" />
      <stop offset="0%" stopColor="currentColor" />
      <stop offset="100%" stopColor="currentColor" />
    </linearGradient>
    <g
      style={{
        stroke: "none",
        strokeWidth: 1,
        fill: "none",
        fillRule: "evenodd"
      }}
    >
      <circle
        style={{
          stroke: "currentColor",
          strokeWidth: 0.936170213
        }}
        cx="12"
        cy="12"
        r="11"
      />
      <circle
        style={{
          fill: "url(#linear-gradient)"
        }}
        cx="12"
        cy="12"
        r="9"
      />
    </g>
  </svg>
);
VolumeIcon.displayName = "VolumeIcon";

export const StyledVolumeIcon = styled(VolumeIcon)`
  color: ${theme.palette.primary.main};
  width: auto;
  height: 100%;
`;
StyledVolumeIcon.displayName = "StyledVolumeIcon";
