import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Formik, FormikHelpers as FormikActions } from "formik";
import styled from "styled-components";
import {
  Grid,
  Select,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Hidden,
  RootRef,
  Typography,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withThemeProvider } from "../../core/withThemeProvider";
import {
  NoStyleLink,
  StyledBackground,
  StyledTitle,
  StyledTitleButtonGrid
} from "../../components/sharedStyledComponents";
import { IRootState } from "../../core/store";
import {
  IDeviceParameterChartItem,
  IViewDeviceParameters
} from "./models/IViewDeviceParameters";
import {
  clearViewParameter,
  expandPanel,
  getDeviceParameterChart,
  getDeviceParameters
} from "./actions/DeviceParametersAction";
import { ViewDeviceParameterHeaderForm } from "./components/ViewDeviceParameterHeader";
import { ViewDeviceParameterChart } from "./components/ViewDeviceParameterChart";
import { Configuration } from "../../core/configuration/config";
import { StyledListIcon } from "../EditDevice";
import { withSnackbar, WithSnackbarProps } from "notistack";
import {
  isSnackbarError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { ViewDeviceParameterTable } from "./components/ViewDeviceParameterTable";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import { RefreshButton } from "../Devices";

interface ISpacing {
  customspacing: number;
}
export const StyledRefreshButton = styled(RefreshButton)`
  && {
    margin: 15px;
  }
`;
StyledRefreshButton.displayName = "StyledRefreshButton";

export const StyledSelect = styled(Select)`
  min-width: 250px;
  margin: 10px 0;
`;
StyledSelect.displayName = "StyledSelect";

export const StyledParameterTableContainer = styled(Grid)`
  height: 350px;
  overflow-y: auto;
`;
StyledParameterTableContainer.displayName = "StyledParameterTableContainer";

export const GraphNotification = styled(Typography)`
  && {
    color: ${Configuration.colors.icon.danger};
    padding: 0 10px 0 20px;
  }
`;
GraphNotification.displayName = "GraphNotification";

export const StretchedExpansionPanel = styled(ExpansionPanel)`
  && {
    width: 100%;
  }
`;
StretchedExpansionPanel.displayName = "StretchedExpansionPanel";

export const StyledChartContainer = styled(Grid)<ISpacing>`
  ${props =>
    Configuration.isMobile
      ? "height: calc(100vh - 530px);  padding: 30px 40px 20px 0"
      : "height: calc(100vh - 300px - " +
        props.customspacing +
        "px); padding: 20px 20px 10px 0"};
  min-height: ${Configuration.isMobile ? "400px" : "calc(100vw / 16 * 9)"};
`;
StyledChartContainer.displayName = "StyledChartContainer";

interface IPathParamsType {
  id: string;
}

interface IStateProps {
  deviceParameters: IViewDeviceParameters;
}

interface IDispatchProps {
  getParameters: (deviceId: string) => Promise<string[] | undefined>;
  getChart: (
    options: IViewDeviceParameters
  ) => Promise<IDeviceParameterChartItem[] | SnackbarError>;
  clearParameter: () => void;
  expandState: () => void;
}

type DeviceParametersProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class DeviceParameters extends React.Component<DeviceParametersProps> {
  private container: React.RefObject<HTMLDivElement> = React.createRef();

  public componentWillUnmount() {
    this.props.clearParameter();
  }

  public async componentDidMount() {
    const {
      getParameters,
      getChart,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    const parameters = await getParameters(id);
    if (isSnackbarError(parameters)) {
      enqueueSnackbar(parameters.message, parameters.options);
    } else {
      const chart = await getChart(this.props.deviceParameters);
      if (isSnackbarError(chart)) {
        enqueueSnackbar(chart.message, chart.options);
      }
    }
  }

  public onSubmit = async (
    values: IViewDeviceParameters,
    { setSubmitting }: FormikActions<IViewDeviceParameters>
  ) => {
    const { enqueueSnackbar, getChart } = this.props;
    const chart = await getChart(values);
    if (isSnackbarError(chart)) {
      enqueueSnackbar(chart.message, chart.options);
    }
    setSubmitting(false);
  };

  public refreshChart = async () => {
    const { enqueueSnackbar, getChart, deviceParameters } = this.props;
    const chart = await getChart(deviceParameters);
    if (isSnackbarError(chart)) {
      enqueueSnackbar(chart.message, chart.options);
    }
  };

  public render() {
    const { current } = this.container;
    const { deviceParameters, expandState } = this.props;

    return (
      <React.Fragment>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <StyledTitleButtonGrid
            container={true}
            item={true}
            justify="flex-end"
            alignItems="center"
          >
            <StyledTitleButtonGrid item={true}>
              View Device Parameters
            </StyledTitleButtonGrid>
            <NoStyleLink to="/devices">
              <Button color="default">
                <StyledListIcon />
                List
              </Button>
            </NoStyleLink>
          </StyledTitleButtonGrid>
        </StyledTitle>

        <StyledBackground padding="0">
          <Hidden mdUp={true}>
            <Grid container={true} item={true} xs={12} justify={"flex-end"}>
              <StyledRefreshButton onClick={this.refreshChart}>
                <RefreshIcon /> Refresh
              </StyledRefreshButton>
              <StretchedExpansionPanel
                expanded={deviceParameters.panelExpanded}
                onChange={expandState}
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  Graph Options
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Formik
                    enableReinitialize={true}
                    initialValues={deviceParameters}
                    onSubmit={this.onSubmit}
                  >
                    {props => <ViewDeviceParameterHeaderForm {...props} />}
                  </Formik>
                </ExpansionPanelDetails>
              </StretchedExpansionPanel>
            </Grid>
          </Hidden>
          <Hidden smDown={true}>
            <Grid container={true}>
              <Grid container={true} item={true} xs={12} lg={7} xl={6}>
                <RootRef rootRef={this.container}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={deviceParameters}
                    onSubmit={this.onSubmit}
                  >
                    {props => <ViewDeviceParameterHeaderForm {...props} />}
                  </Formik>
                </RootRef>
              </Grid>
              <StyledParameterTableContainer
                container={true}
                item={true}
                xs={12}
                lg={5}
                xl={6}
              >
                <ViewDeviceParameterTable
                  parameter={deviceParameters.parameter}
                  aggregationEnabled={deviceParameters.aggregationEnabled}
                  aggregationFunction={deviceParameters.aggregationFunction}
                  values={deviceParameters.chart}
                />
              </StyledParameterTableContainer>
            </Grid>
          </Hidden>
          {deviceParameters.chartOverload && (
            <GraphNotification variant="caption">
              Too many data points to display. In order to avoid any delay on
              rendering the graph, it is limited to only show maximum number of
              500 data points. You can try reducing the time period or changing
              aggregation options
            </GraphNotification>
          )}
          <StyledChartContainer
            customspacing={current ? current.clientHeight : 0}
            container={true}
            item={true}
            xs={12}
          >
            <ViewDeviceParameterChart data={deviceParameters} />
          </StyledChartContainer>
          {!deviceParameters.aggregationEnabled && (
            <Hidden mdUp={true}>
              <StretchedExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  {`List of ${deviceParameters.parameter || "..."} readings`}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ViewDeviceParameterTable
                    parameter={deviceParameters.parameter}
                    aggregationEnabled={deviceParameters.aggregationEnabled}
                    aggregationFunction={deviceParameters.aggregationFunction}
                    values={deviceParameters.chart}
                  />
                </ExpansionPanelDetails>
              </StretchedExpansionPanel>
            </Hidden>
          )}
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  deviceParameters: state.deviceParameters
});

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    getParameters: (deviceId: string) =>
      dispatch(getDeviceParameters(deviceId)),
    getChart: (options: IViewDeviceParameters) =>
      dispatch(getDeviceParameterChart(options)),
    clearParameter: () => dispatch(clearViewParameter()),
    expandState: () => dispatch(expandPanel())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(DeviceParameters)));
