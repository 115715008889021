import { theme } from "../core/withThemeProvider";

export interface IResourceLink {
  name: string;
  id: string;
}
export interface ICurrentUser {
  issuedAt: number;
  subs: string;
  userId: string;
  subId: string;
  roleId: string;
  username: string;
}

export interface IGlobalState {
  menuOpen: boolean;
  toolbarMenuOpen: any;
  loginError: boolean;
  sessionExpired: boolean;
  resources: IResourceLink[];
  devicesConnected: number;
  resourcesLoaded: boolean;
  user?: ICurrentUser;
  refreshRequired: boolean;
  noHeader: boolean;
  menuDisabled: boolean;
}

export const initialGlobalState: IGlobalState = {
  devicesConnected: 0,
  menuOpen: window.innerWidth > theme.breakpoints.values.md,
  noHeader: false,
  refreshRequired: false,
  resources: [],
  menuDisabled: false,
  resourcesLoaded: false,
  loginError: false,
  sessionExpired: false,
  toolbarMenuOpen: null
};
