import React from "react";
import { IDeviceParameterChartItem } from "../models/IViewDeviceParameters";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import styled from "styled-components";
import { Configuration } from "../../../core/configuration/config";
interface IViewDeviceParameterTable {
  aggregationFunction: string[];
  aggregationEnabled: boolean;
  parameter: string;
  values: IDeviceParameterChartItem[];
}
export const TimeCell = styled(TableCell)`
  width: 150px;
`;
TimeCell.displayName = "TimeCell";
export const StyledTableHead = styled(TableHead)`
  && th {
    background-color: white;
  }
`;
StyledTableHead.displayName = "StyledTableHead";

export const ViewDeviceParameterTable: React.FunctionComponent<IViewDeviceParameterTable> = ({
  aggregationEnabled,
  parameter,
  values
}) => {
  return !Boolean(aggregationEnabled) ? (
    <Table stickyHeader={true}>
      <StyledTableHead>
        <TableRow>
          <TableCell>{parameter}</TableCell>
          <TimeCell>Time</TimeCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {values
          .filter(({ value }) => Boolean(value) || typeof value === "number")
          .reverse()
          .filter((_, i) => i < Configuration.viewDeviceParameterTableLength)
          .map(({ value, unit, time }, index) => (
            <TableRow key={index}>
              <TableCell>
                {value} {unit}
              </TableCell>
              <TimeCell>{time}</TimeCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  ) : null;
};
