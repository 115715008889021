import React from "react";
import { TableRow, TableHead } from "@material-ui/core";

import { CustomizedTableCell } from "../../../components/DeviceTable";
import { TableLoadingIndicator } from "../../../../components/TableStyles";

interface IDeviceAlertTableHead {
  hide: boolean;
  isLoading?: boolean;
}
export const DeviceDownlinkTableHead: React.FunctionComponent<IDeviceAlertTableHead> = ({
  hide,
  isLoading
}: IDeviceAlertTableHead) => (
  <TableHead>
    <TableRow>
      <CustomizedTableCell header={1} width="150px" textalign="center">
        created at
      </CustomizedTableCell>
      <CustomizedTableCell header={1} width="150px">
        Status
      </CustomizedTableCell>
      <CustomizedTableCell header={1} width="300px">
        Network ID
      </CustomizedTableCell>
      <CustomizedTableCell header={1}>
        Data
      </CustomizedTableCell>
      <CustomizedTableCell
        header={1}
        width="125px"
        type="button"
      />
    </TableRow>
    {isLoading && <TableLoadingIndicator colspan={8} />}
  </TableHead>
);
