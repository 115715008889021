import {
  IChartQueryResult,
  IDeviceCollection,
  initialDeviceCollection
} from "../models/IDevice";
import { ActionType } from "../../core/ActionTypes";
import { IInfluxTableQuery } from "../../Resources/models/IResource";
import { IDeviceAlertApi } from "../DeviceAlerts/models/IAlerts";
import {
  getDeviceThresholds,
  getMaxMinThresholds
} from "../DeviceAlerts/services/DeviceAlertService";

export type deviceCollectionReducerTypes =
  | { type: ActionType.DEVICE_COLLECTION_LOADING; payload: boolean }
  | { type: ActionType.GET_DEVICE_COLLECTION; payload: IDeviceCollection }
  | {
      type: ActionType.CHANGE_DEVICE_COLLECTION_MEASUREMENT_PERIOD;
      payload: string;
    } // todo switch measurement periods to enum
  | { type: ActionType.COLLECTION_MEASUREMENTS_LOADING; payload: boolean }
  | {
      type: ActionType.GET_DEVICE_COLLECTION_MEASUREMENTS;
      payload: IInfluxTableQuery[];
    }
  | { type: ActionType.COLLECTION_CHART_LOADING; payload: boolean }
  | {
      type: ActionType.GET_DEVICE_COLLECTION_CHART;
      payload: IChartQueryResult[];
    }
  | {
      type: ActionType.GET_COLLECTION_CHART_THRESHOLDS;
      payload: IDeviceAlertApi[];
    }
  | {
      type: ActionType.REINITIALIZE;
    };

export function deviceCollectionReducer(
  state: IDeviceCollection = { ...initialDeviceCollection },
  action: deviceCollectionReducerTypes
): IDeviceCollection {
  switch (action.type) {
    case ActionType.DEVICE_COLLECTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.REINITIALIZE:
      state = { ...initialDeviceCollection };
      break;
    case ActionType.GET_DEVICE_COLLECTION:
      state = {
        ...action.payload
      };
      break;
    case ActionType.CHANGE_DEVICE_COLLECTION_MEASUREMENT_PERIOD:
      state = {
        ...state,
        measurementPeriod: action.payload
      };
      break;
    case ActionType.COLLECTION_MEASUREMENTS_LOADING:
      state = {
        ...state,
        members: state.members.map(device => {
          return {
            ...device,
            measurements: { ...device.measurements, isLoading: action.payload }
          };
        })
      };
      break;
    case ActionType.GET_DEVICE_COLLECTION_MEASUREMENTS:
      state = {
        ...state,
        members: state.members.map(device => {
          const deviceMeasurements = action.payload.filter(
            measurements => measurements.device === device.shortId
          );
          return {
            ...device,
            measurements: {
              ...device.measurements,
              values:
                deviceMeasurements.length === 1
                  ? deviceMeasurements[0]
                  : device.measurements.values
            }
          };
        })
      };
      break;
    case ActionType.COLLECTION_CHART_LOADING:
      state = {
        ...state,
        members: state.members.map(device => {
          return {
            ...device,
            measurementsChart: {
              ...device.measurementsChart,
              isLoading: action.payload
            }
          };
        })
      };
      break;
    case ActionType.GET_DEVICE_COLLECTION_CHART:
      state = {
        ...state,
        members: state.members.map(device => {
          const deviceChart = action.payload.filter(
            chart => device.shortId === chart.device
          );
          return {
            ...device,
            measurementsChart: {
              ...device.measurementsChart,
              values: deviceChart.length === 1 ? deviceChart[0].chartData : []
            }
          };
        })
      };
      break;
    case ActionType.GET_COLLECTION_CHART_THRESHOLDS:
      state = {
        ...state,
        members: state.members.map(device => {
          const thresholds = getDeviceThresholds(
            device.id,
            device.template,
            action.payload
          );
          const domain = getMaxMinThresholds(
            thresholds.critical.concat(thresholds.warning)
          );
          return {
            ...device,
            measurementsChart: {
              ...device.measurementsChart,
              thresholds: { ...thresholds },
              domain: { ...domain }
            }
          };
        })
      };
      break;
    default:
      break;
  }
  return state;
}
