import { ActionType } from "../../core/ActionTypes";
import {
  ILocationsHistory,
  ILocationsHistoryQuery,
  initialLocationsHistory
} from "../models/ILocationsHistory";

export type deviceLocationsHistoryReducerTypes =
  | {
      type: ActionType.DEVICE_LOCATIONS_HISTORY_LOADING;
      payload: boolean | ILocationsHistoryQuery;
    }
  | {
      type: ActionType.GET_DEVICE_LOCATIONS_HISTORY;
      payload: ILocationsHistory;
    };

export const deviceLocationsHistoryReducer = (
  state: ILocationsHistory = { ...initialLocationsHistory },
  action: deviceLocationsHistoryReducerTypes
): ILocationsHistory => {
  switch (action.type) {
    case ActionType.DEVICE_LOCATIONS_HISTORY_LOADING:
      state = {
        ...state,
        isLoading: typeof action.payload === "boolean" ? action.payload : true,
        ...(typeof action.payload !== "boolean" && action.payload)
      };
      break;
    case ActionType.GET_DEVICE_LOCATIONS_HISTORY:
      state = {
        ...state,
        ...action.payload,
        devices:
          action.payload.devices.length > 0
            ? action.payload.devices
            : state.devices,
        viewport: {
          ...state.viewport,
          longitude: action.payload.viewport.longitude,
          latitude: action.payload.viewport.latitude,
          zoom: action.payload.viewport.zoom
        }
      };
      break;
    default:
      break;
  }
  return state;
};
