import React from "react";
import styled from "styled-components";
import { Button, TableRow, ListItemText } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { NoStyleLink } from "../../../../components/sharedStyledComponents";
import { CustomizedTableCell } from "../../../components/DeviceTable";
import { IDeviceAlert } from "../../models/IAlerts";
import { ITableDeleteCell } from "../../../../core/models/IShared";
import { IDevice } from "../../../models/IDevice";
import { getDeviceAlertParameterName } from "../../services/DeviceAlertService";

interface IDeviceAlertTableRow {
  alert: IDeviceAlert;
  hide: boolean;
  handleDialogOpen: (
    itemInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
  device: IDevice;
}

export const NameSerial = styled(ListItemText)`
  && > * {
    font-size: 0.95em;
    white-space: normal;
    word-break: break-word;
  }
`;
NameSerial.displayName = "NameSerial";

export const TableButton = styled(Button)`
  & svg {
    font-size: 18px;
    margin-left: 5px;
  }
`;
TableButton.displayName = "TableButton";

export const DeviceAlertTableRow: React.FunctionComponent<IDeviceAlertTableRow> = ({
  alert: {
    id,
    message,
    alertLevel,
    threshold,
    shortId,
    device: deviceId,
    parameter: { label }
  },
  hide,
  handleDialogOpen,
  device: { basicCalculationProperties }
}: IDeviceAlertTableRow) => {
  return (
    <TableRow key={id}>
      <CustomizedTableCell>
        {getDeviceAlertParameterName(basicCalculationProperties, label)}
      </CustomizedTableCell>
      <CustomizedTableCell>{message}</CustomizedTableCell>
      <CustomizedTableCell>{threshold || "N/A"}</CustomizedTableCell>
      <CustomizedTableCell>{alertLevel}</CustomizedTableCell>
      <CustomizedTableCell hide={hide ? 1 : 0} textalign="center" type="button">
        <NoStyleLink to={`/devices/${deviceId}/alerts/${shortId}`}>
          <TableButton color="primary">
            Edit <EditIcon />
          </TableButton>
        </NoStyleLink>
        <br />
        <Button
          color="primary"
          onClick={handleDialogOpen({
            visible: true,
            id,
            title: `${getDeviceAlertParameterName(
              basicCalculationProperties,
              label
            )}: "${message}"`
          })}
        >
          Delete <DeleteIcon />
        </Button>
      </CustomizedTableCell>
    </TableRow>
  );
};
