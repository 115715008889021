export const TypedValueToHex = (type: string, value: string) => {
  if (value.length === 0) {
    return "";
  }
  const isNegative = value.charAt(0) === "-";
  const { isLE, intIndex, hexSymbols } = parseDownlinkFormat(type);
  if (intIndex < 0) {
    throw new TypeError("Unknown Type. Expected Integer.");
  }
  if (hexSymbols % 1) {
    throw new TypeError("Non Integer bytes amount");
  }
  const MAXHEX = "0x" + new Array(hexSymbols).fill("F").join("");
  let HexValue: string;
  if (isNegative) {
    HexValue = (BigInt(MAXHEX) + BigInt(1) + BigInt(value)).toString(16);
  } else {
    HexValue = parseInt(value, 10).toString(16);
  }
  if (HexValue.length % 2 !== 0) {
    HexValue = "0" + HexValue;
  }
  if (isLE) {
    return (HexValue.match(/.{1,2}/g) as string[])
      .reverse()
      .join("")
      .padEnd(hexSymbols, "0")
      .toUpperCase();
  } else {
    return HexValue.padStart(hexSymbols, "0").toUpperCase();
  }
};

export const parseDownlinkFormat = (format: string) => {
  const isLE = format.split("_").pop() === "le";
  const isRE = !isLE;
  const intIndex = format.indexOf("int");
  const unsigned = format.charAt(0) === "u";
  const signed = !unsigned;
  const bit = parseInt(
    format.split("_")[0].substr(format.indexOf("int") + 3),
    10
  );
  const bytesAmount = bit / 8;
  const hexSymbols = bytesAmount * 2;
  const maxUint = BigInt(Math.pow(2, bit) - 1);
  const minInt = (Math.pow(2, bit) / 2) * -1;
  const maxInt = Math.pow(2, bit) / 2 - 1;
  return {
    isLE,
    isRE,
    intIndex,
    unsigned,
    signed,
    bit,
    bytesAmount,
    hexSymbols,
    min: unsigned ? 0 : minInt,
    max: unsigned ? maxUint : maxInt
  };
};
