export const expressionConfig = {
  variables: {
    abs: { kind: "func" },
    acos: { kind: "func" },
    acosh: { kind: "func" },
    asin: { kind: "func" },
    asinh: { kind: "func" },
    atan: { kind: "func" },
    atan2: { kind: "func" },
    atanh: { kind: "func" },
    cbrt: { kind: "func" },
    ceil: { kind: "func" },
    copysign: { kind: "func" },
    cos: { kind: "func" },
    cosh: { kind: "func" },
    dim: { kind: "func" },
    erf: { kind: "func" },
    erfc: { kind: "func" },
    erfcinv: { kind: "func" },
    erfinv: { kind: "func" },
    exp: { kind: "func" },
    exp2: { kind: "func" },
    expm1: { kind: "func" },
    float32bits: { kind: "func" },
    float32frombits: { kind: "func" },
    float64bits: { kind: "func" },
    float64frombits: { kind: "func" },
    floor: { kind: "func" },
    frexp: { kind: "func" },
    gamma: { kind: "func" },
    hypot: { kind: "func" },
    ilogb: { kind: "func" },
    inf: { kind: "func" },
    isinf: { kind: "func" },
    isnan: { kind: "func" },
    j0: { kind: "func" },
    j1: { kind: "func" },
    jn: { kind: "func" },
    ldexp: { kind: "func" },
    lgamma: { kind: "func" },
    log: { kind: "func" },
    log10: { kind: "func" },
    log1p: { kind: "func" },
    log2: { kind: "func" },
    logb: { kind: "func" },
    max: { kind: "func" },
    min: { kind: "func" },
    mod: { kind: "func" },
    modf: { kind: "func" },
    nan: { kind: "func" },
    nextafter: { kind: "func" },
    nextafter32: { kind: "func" },
    pow: { kind: "func" },
    pow10: { kind: "func" },
    remainder: { kind: "func" },
    round: { kind: "func" },
    roundtoeven: { kind: "func" },
    signbit: { kind: "func" },
    sin: { kind: "func" },
    sincos: { kind: "func" },
    sinh: { kind: "func" },
    sqrt: { kind: "func" },
    tan: { kind: "func" },
    tanh: { kind: "func" },
    trunc: { kind: "func" },
    y0: { kind: "func" },
    y1: { kind: "func" },
    yn: { kind: "func" }
  }
};
