import { ICollection, ILoadable } from "../../../core/models/MixinInterfaces";
import {
  IDetermination,
  IApiResponseHeader
} from "../../../core/models/MixinInterfaces";
import { ITableDeleteCell } from "../../../core/models/IShared";
import { INotification } from "./ITemplate";

export interface ITags {
  key: string;
}

export interface IDeviceAlertOperation {
  id: string;
  name: string;
}

export interface IDevcieAlertParameterSelection {
  label: string;
  value: string;
  unitText: string;
}

export interface IInterval {
  value: string;
  multiplier: string;
}

export interface IDeviceAlertApi extends IApiResponseHeader {
  "@context": string;
  name: string;
  message: string;
  device: string;
  parameter: string;
  subscription: string;
  operator: string;
  threshold: string;
  minimumRepeats: number;
  missedDataInterval?: number;
  alertLevel: string;
  renotifyInterval: number;
  notifyOnClearing: boolean;
  autoClear: boolean;
  notificationTemplate: string;
  tags: ITags;
}

export interface IDeviceAlertCollectionApi
  extends IApiResponseHeader,
    ICollection {
  "@context": string;
  member: IDeviceAlertApi[];
}

export interface IDeviceAlert extends ILoadable, IDetermination {
  context: string;
  name: string;
  message: string;
  device: string;
  deviceSerial: string;
  parameter: IDevcieAlertParameterSelection;
  subscription: string;
  operator: string;
  threshold: string;
  minimumRepeats: string;
  missedDataInterval: IInterval;
  alertLevel: string;
  renotifyInterval: IInterval;
  notifyOnClearing: boolean;
  autoClear: boolean;
  notificationTemplate: string;
  deviceAlertDelete: ITableDeleteCell;
  tags: ITags;
  notification: INotification[];
  notificationKind: string[];
  thresholdDisabled: boolean;
  missedDataDisabled: boolean;
}

export interface IDeviceAlertCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context: string;
  members: IDeviceAlert[];
}

export const initialDeviceAlert: IDeviceAlert = {
  context: "",
  isLoading: false,
  id: "",
  shortId: "",
  type: "",
  name: "",
  message: "",
  device: "",
  deviceSerial: "",
  parameter: { label: "", value: "", unitText: "" },
  subscription: "",
  operator: "",
  threshold: "",
  minimumRepeats: "1",
  missedDataInterval: { value: "2", multiplier: "h" },
  alertLevel: "",
  renotifyInterval: { value: "2", multiplier: "m" },
  notifyOnClearing: true,
  autoClear: false,
  notificationTemplate: "",
  deviceAlertDelete: {
    visible: false,
    id: "",
    title: ""
  },
  tags: { key: "" },
  notification: [],
  notificationKind: [],
  thresholdDisabled: false,
  missedDataDisabled: false
};

export const initialDeviceAlertCollection: IDeviceAlertCollection = {
  context: "",
  isLoading: false,
  id: "",
  shortId: "",
  type: "",
  members: [],
  totalItems: 0,
  view: { "@id": "", "@type": "", first: "", last: "", next: "", previous: "" }
};
