import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "./Dashboard/Dashboard";
import Devices from "./Devices/Devices";
import AddNewDevice from "./AddNewDevice/AddNewDevice";
import EditDevice from "./Devices/EditDevice";
import DeviceParameters from "./Devices/DeviceParameters/DeviceParameters";
import DeviceAlerts from "./Devices/DeviceAlerts/DeviceAlerts";
import DeviceDownlinks from "./Devices/DeviceDownlinks/DeviceDownlinks";
import EditDeviceDownlinks from "./Devices/DeviceDownlinks/EditDeviceDownlinks";
import EditDeviceAlert from "./Devices/DeviceAlerts/EditDeviceAlert";
import EditUserProfile from "./Profile/EditUserProfile";
import ExportDevice from "./ExportDevice/ExportDevice";
import { InstallMobileApp } from "./InstallMobileApp/InstallMobileApp";
// import Resources from "./Resources/Resources";
// import Location from "./Location/Location";
/*
const renderResources = (props: any) => (
  <Resources key={props.match.params.id} {...props} />
);*/

const homePageRedirect = (props: any) => {
  return <Redirect to="/devices" {...props} />;
};

export const Routes = () => {
  return (
    <Switch>
      <Route exact={true} path="/" render={homePageRedirect} />
      <Route exact={true} path="/dashboard" component={Dashboard} />
      <Route exact={true} path="/devices" component={Devices} />
      {/* <Route path="/resources/:id" render={renderResources} /> */}
      {/* <Route path="/locations" component={Location} />  */}
      <Route exact={true} path="/devices/add" component={AddNewDevice} />
      <Route exact={true} path="/devices/:id" component={EditDevice} />
      <Route
        exact={true}
        path="/devices/:id/graph"
        component={DeviceParameters}
      />
      <Route exact={true} path="/devices/:id/alerts" component={DeviceAlerts} />
      <Route path="/devices/:id/alerts/:alertId" component={EditDeviceAlert} />
      <Route
        exact={true}
        path="/devices/:id/downlinks"
        component={DeviceDownlinks}
      />
      <Route
        exact={true}
        path="/devices/:id/downlinks/send"
        component={EditDeviceDownlinks}
      />
      <Route path="/users/:id" component={EditUserProfile} />
      <Route path="/export" component={ExportDevice} />
      <Route path="/mobileapp" component={InstallMobileApp} />
    </Switch>
  );
};
