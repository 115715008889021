const APIBaseURL = "https://api.ellenex.net";

export const ProductionConfig = {
  APIBaseURL,
  EdiAPIUrl: APIBaseURL + "/v1/edi",
  EsmaAPIUrl: APIBaseURL + "/v1/esma",
  EaraAPIUrl: APIBaseURL + "/v1/eara",
  EdnaAPIUrl: APIBaseURL + "/v1/edna",
  ExportAPIUrl: APIBaseURL + "/exports",
  ElaAPIUrl: APIBaseURL + "/v1/ela",
  EdlaAPIUrl: APIBaseURL + "/v1/edla",

  ediApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  esmaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  eramaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  earaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  authApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  ednaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  elaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
  edlaApikey: "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",

  auth: {
    cognitoRoot: "https://auth.ellenex.net/",
    tokenUrl: APIBaseURL + "/v1/auth/token",
    login: "login",
    logout: "logout",
    redirectUri: document.location ? document.location.origin : "",
    clientId: "go7ub2sqap0m0d8b618a8it2c"
  },
  development: false,
  geo: {
    mapboxKey:
      "pk.eyJ1IjoiZXZ0LXZsYWQiLCJhIjoiY2s2MXlheTB3MDd1NzNkbzQwbXQ5OHJ3cSJ9.F0gdRvZRB0aTo1B2tf4pJg",
    googleMapsKey: "AIzaSyABJQFZExwCXTaz1j7A6mRBl1f7lB9m6m0",
    trips: {
      splitTripTimeout: 15 * 60 * 60 * 1000,
      trailLength: 1000,
      animationSpeedMultiplier: 1 * 10000,
      iconSizeMultiplier: 3,
      pathWidth: { min: 4, max: 10 } // px
    },
    mapCenter: {
      lat: -25.61,
      lng: 134.35472
    },
    defaultZoom: {
      noDevice: 4,
      oneDevice: 15
    },
    clustering: {
      minZoom: 3,
      maxZoom: 25,
      radius: 40
    }
  },
  influxConfig: {
    protocol: "https",
    host: "api.ellenex.net",
    port: "",
    database: "v1",
    options: {
      headers: {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
        "x-lnx-token": ""
      },
      hostname: "api.ellenex.net/v1/influxdb"
    }
  }
};
