import React from "react";
import { TableBody } from "@material-ui/core";

import { DeviceTableWrapper } from "../../components/DeviceTable";
import { IDeviceDownlink } from "../models/IDownlinks";
import { DeviceDownlinkTableHead } from "./DeviceDownlinkTable/DeviceDownlinkTableHead";
import { DeviceDownlinkTableRow } from "./DeviceDownlinkTable/DeviceDownlinkTableRow";
import { DeviceDownlinkTableFooter } from "./DeviceDownlinkTable/DeviceDownlinkTableFooter";
import { IPaginationView } from "../../../core/models/MixinInterfaces";

export interface IDownlinkTableProps {
  isLoading: boolean;
  rows: IDeviceDownlink[];
  pagination: IPaginationView;
  totalItems: number;
  onChangePage: (page: string) => void;
  deleteDownlink: (id: string) => Promise<void>;
}

export const DownlinkTable: React.FunctionComponent<IDownlinkTableProps> = (
  props
) => {
  const { onChangePage, totalItems, isLoading, rows, deleteDownlink } = props;

  const isDeviceDownlinkCollection = Boolean(
    typeof totalItems === "number" && onChangePage
  );

  return (
    <DeviceTableWrapper>
      <DeviceDownlinkTableHead
        hide={!isDeviceDownlinkCollection}
        isLoading={isLoading}
      />
      <TableBody>
        {rows.map((row) => (
          <DeviceDownlinkTableRow
            key={row.id}
            downlink={row}
            deleteDownlink={deleteDownlink}
            hide={!isDeviceDownlinkCollection}
          />
        ))}
      </TableBody>
      <DeviceDownlinkTableFooter {...props} />
    </DeviceTableWrapper>
  );
};
DownlinkTable.displayName = "DownlinkTable";
