import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { unregister } from "./core/configuration/registerServiceWorker";
import { store } from "./core/store";
import Root from "./Root/Root";
import { globalStyles } from "./core/styles.global";
import versionWarcher from "./core/lifecycle/versionWatcher";
import ScrollTop from "./core/lifecycle/ScrollTop";
import { history } from "./core/configuration/history";
import { fetchStyles } from "./core/withThemeProvider";
import { Routes } from "./router";

const App = () => (
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <Router history={history}>
        <ScrollTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Root>
              <Routes />
            </Root>
          </MuiPickersUtilsProvider>
        </ScrollTop>
      </Router>
    </SnackbarProvider>
  </Provider>
);
fetchStyles().finally(() => {
  ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
  unregister();
  globalStyles();
  versionWarcher();
});
