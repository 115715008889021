import * as React from "react";
import moment from "moment";
import { FieldProps } from "formik";
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { Configuration } from "../core/configuration/config";

export type IDateTimePickerProps = FieldProps & KeyboardDateTimePickerProps;

export const FormikDateTimePicker: React.ComponentType<IDateTimePickerProps> = ({
  form: { setFieldError, setFieldValue, errors },
  field: { name, value },
  ...other
}: IDateTimePickerProps) => {
  const { format, mask, maskChar } = Configuration.dateTimePicker;
  const sfv = (date: MaterialUiPickersDate) => {
    if (date) {
      setFieldValue(name, date ? date : moment(), true);
    }
  };
  const setError = (error: React.ReactNode) => {
    if (error !== currentError) {
      setFieldError(name, error as any);
    }
  };
  const currentError = errors[name];
  return (
    <KeyboardDateTimePicker
      name={name}
      value={value}
      autoOk={true}
      format={format}
      ampm={false}
      mask={mask}
      maskChar={maskChar}
      onChange={sfv}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={setError}
      animateYearScrolling={false}
      {...other}
    />
  );
};
FormikDateTimePicker.displayName = "FormikDateTimePicker";
