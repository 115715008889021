import { IRole, IRoleApi } from "../models/IRole";
import { getShortId } from "../../core/utilities/ServiceUtilities";

export const roleFromApi = (role: IRoleApi): IRole => {
  return {
    appliedPolicies: role.appliedPolicies,
    context: role["@context"],
    id: role["@id"],
    name: role.name,
    shortId: getShortId(role["@id"]),
    subscription: role.subscription,
    type: role["@type"]
  };
};
