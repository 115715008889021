import * as React from "react";
import MuiSwitch, {
  SwitchProps as MuiSwitchProps
} from "@material-ui/core/Switch";
import { FieldProps } from "formik";
import { Omit } from "../core/models/types";
import FormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps
} from "@material-ui/core/FormControlLabel/FormControlLabel";

export interface ISwitchProps
  extends FieldProps,
    Omit<
      MuiSwitchProps,
      "form" | "name" | "onChange" | "value" | "defaultChecked"
    > {
  label: Omit<
    MuiFormControlLabelProps,
    "checked" | "name" | "onChange" | "value" | "inputRef"
  >;
}

export const fieldToSwitch = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: ISwitchProps): MuiSwitchProps => {
  return {
    disabled: isSubmitting || disabled,
    ...props,
    ...field,
    value: field.name,
    checked: field.value
  };
};

export const Switch: React.ComponentType<ISwitchProps> = ({ ...props }) => (
  <FormControlLabel
    label={props.label}
    control={<MuiSwitch {...fieldToSwitch(props)} />}
  />
);

Switch.displayName = "FormikMaterialUISwitch";
