import { ActionType } from "../../core/ActionTypes";
import { initialExportDevice, IExportDevice } from "../models/IExportDevice";

export type exportDeviceReducerTypes =
  | { type: ActionType.EXPORT_INITIALIZING; payload: boolean }
  | { type: ActionType.EXPORT_INITIALIZED; payload: IExportDevice }
  | { type: ActionType.EXPORT_DEVICE; payload: IExportDevice }
  | { type: ActionType.REINITIALIZE };

export function exportDeviceReducer(
  state: IExportDevice = { ...initialExportDevice },
  action: exportDeviceReducerTypes
): IExportDevice {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialExportDevice
      };
      break;
    case ActionType.EXPORT_INITIALIZED:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.EXPORT_DEVICE:
      state = {
        ...state,
        ...action.payload,
        isLoading: state.isLoading
      };
      break;
    case ActionType.EXPORT_INITIALIZING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    default:
      break;
  }
  return state;
}
