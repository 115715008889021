import * as React from "react";
import styled from "styled-components";
import { Button, TableRow, ListItemText, InputLabel } from "@material-ui/core";
import {
  Edit as EditIcon,
  BarChart as ChartIcon,
  AddAlert,
  TapAndPlay
} from "@material-ui/icons";

import { LastSeenCell } from "../DeviceTableCustomCells/LastSeenCell";
import { ChartCell } from "../DeviceTableCustomCells/ChartCell";
import { TemperatureCell } from "../DeviceTableCustomCells/TemperatureCell";
import { BatteryCell } from "../DeviceTableCustomCells/BatteryCell";
import { RSSICell } from "../DeviceTableCustomCells/RSSICell";
import { NoStyleLink } from "../../../components/sharedStyledComponents";
import { CustomizedTableCell } from "../DeviceTable";
import { IDevice } from "../../models/IDevice";
import { Configuration } from "../../../core/configuration/config";

interface IDeviceTableRow {
  device: IDevice;
  hide: boolean;
}

export const NameSerial = styled(ListItemText)`
  && > * {
    font-size: 0.95em;
    white-space: normal;
    word-break: break-word;
  }
`;
NameSerial.displayName = "NameSerial";

export const StyledSerial = styled(InputLabel)`
  && {
    font-size: 0.9em;
    color: gray;
    white-space: normal;
    word-break: keep-all;
  }
`;
StyledSerial.displayName = "StyledSerial";

export const TableButton = styled(Button)`
  & svg {
    font-size: 16px;
    margin-left: 3px;
  }
`;
TableButton.displayName = "TableButton";

export const StyledAlertIcon = styled(AddAlert)`
  && {
    font-size: 16px;
    color: ${Configuration.colors.icon.blue};
  }
`;
StyledAlertIcon.displayName = "StyledAlertIcon";

export const DeviceTableRow: React.FunctionComponent<IDeviceTableRow> = ({
  device: {
    name,
    serial,
    measurements,
    measurementsChart,
    template: { parameters },
    note,
    shortId
  },
  hide
}) => (
  <TableRow style={{ paddingRight: 20 }}>
    <CustomizedTableCell>
      <NameSerial
        primary={name}
        secondary={<StyledSerial>{serial}</StyledSerial>}
      />
    </CustomizedTableCell>
    <LastSeenCell measurements={measurements} />
    <ChartCell hide={hide ? 1 : 0} measurements={measurementsChart} />
    <TemperatureCell measurements={measurements} parameters={parameters} />
    <BatteryCell measurements={measurements} />
    <RSSICell measurements={measurements} />
    <CustomizedTableCell hide={hide ? 1 : 0}>{note}</CustomizedTableCell>
    <CustomizedTableCell
      hide={hide ? 1 : 0}
      type="button"
      verticalalign="top"
      paddingtop="20px">
      <NoStyleLink to={`/devices/${shortId}/alerts`}>
        <TableButton color="primary">
          Alerts <StyledAlertIcon />
        </TableButton>
      </NoStyleLink>
      <NoStyleLink to={`/devices/${shortId}`}>
        <TableButton color="primary">
          Edit <EditIcon />
        </TableButton>
      </NoStyleLink>
    </CustomizedTableCell>
    <CustomizedTableCell
      hide={hide ? 1 : 0}
      type="button"
      verticalalign="top"
      paddingtop="20px">
      <NoStyleLink to={`/devices/${shortId}/downlinks`}>
        <TableButton color="primary">
          Downlinks <TapAndPlay />
        </TableButton>
      </NoStyleLink>
      <NoStyleLink to={`/devices/${shortId}/graph`}>
        <TableButton color="primary">
          Graph <ChartIcon />
        </TableButton>
      </NoStyleLink>
    </CustomizedTableCell>
  </TableRow>
);
