import { IApiResponseHeader } from "../../core/models/MixinInterfaces";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import { ISubscription } from "../../Subscriptions/models/ISubscription";
import { IRole } from "../../Roles/models/IRole";

export interface ISubscriptionWithRole extends ISubscription {
  roles: IRole[];
}

interface IUIPreferences {
  devices: {
    includeParameters: string[] | null; // todo: find out possible types for that variable
  };
}

export interface IUserProfileAPI extends IApiResponseHeader {
  activeRole: string;
  activeSubscription: string;
  hasRoles: {
    [subscription: string]: string[];
  };
  email: string;
  mobile: string;
  name: string;
  notificationChannels?: string[];
  uiPreferences: IUIPreferences;
  status: string;
}

export interface IUserProfile extends IDetermination, ILoadable {
  activeRole: string;
  activeSubscription: string;
  hasRoles: ISubscriptionWithRole[];
  email: string;
  mobile: string;
  name: string;
  countryCode: string;
  smsNotification: boolean;
  emailNotification: boolean;
  uiPreferences: IUIPreferences;
  status: string;
  $$raw?: IUserProfileAPI;
}

export const initialUserProfile: IUserProfile = {
  activeRole: "",
  activeSubscription: "",
  hasRoles: [],
  shortId: "",
  isLoading: true,
  id: "",
  type: "",
  countryCode: "375",
  email: "",
  emailNotification: false,
  mobile: "",
  name: "",
  smsNotification: false,
  uiPreferences: {
    devices: { includeParameters: [] }
  },
  status: ""
};
