import * as React from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { Typography, RootRef, Button, Grid } from "@material-ui/core";

import { theme, withThemeProvider } from "../core/withThemeProvider";
import {
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import { IRootState } from "../core/store";
import { IGlobalState } from "../models/IGlobalState";
import { getLocations } from "../Location/actions/locationAction";
import { ILocation } from "../Location/models/IGeolocation";
import { Configuration } from "../core/configuration/config";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { DeviceClusteringMap } from "../Location/components/DeviceClusteringMap";
import { deepLinkRedirect } from "../core/utilities/ServiceUtilities";

export const DevicesMap = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 20px;
`;
DevicesMap.displayName = "DevicesMap";

export const DashboardButton = styled(Button)`
  && {
    margin: ${theme.spacing(1)}px;
  }
`;
DashboardButton.displayName = "DashboardButton";

interface IDevicesStateProps {
  globalState: IGlobalState;
  profile: IUserProfile;
  globalMap: ILocation;
}

interface IDevicesDispatchProps {
  getLocations: (height: number, width: number) => void;
}

export class Dashboard extends React.Component<
  IDevicesStateProps & IDevicesDispatchProps
> {
  private container: React.RefObject<HTMLDivElement> = React.createRef();
  public componentDidMount() {
    if (this.container.current) {
      this.props.getLocations(
        this.container.current.clientHeight,
        this.container.current.clientWidth
      );
    }
  }
  public openMobileApp = (url: string) => () => {
    deepLinkRedirect(url);
  };
  public render() {
    const {
      globalState: { devicesConnected, noHeader },
      globalMap: { center, zoom, locations, isLoading },
      profile: { name }
    } = this.props;
    const { googleMapsKey, defaultZoom, mapCenter } = Configuration.geo;
    return (
      <React.Fragment>
        {!noHeader && <StyledTitle>Dashboard</StyledTitle>}
        <StyledBackground>
          <Typography variant="h5" align="center">
            Welcome, {name}
          </Typography>
          <br />
          <Typography variant="h6" align="center">
            You have {devicesConnected} devices connected.
          </Typography>
          <br />
          <Grid container={true} justify="center">
            <DashboardButton
              variant="contained"
              color="primary"
              onClick={this.openMobileApp("/devices")}
            >
              Device List
            </DashboardButton>
            <DashboardButton
              variant="contained"
              color="primary"
              onClick={this.openMobileApp("/devices/add")}
            >
              Add New Device
            </DashboardButton>
          </Grid>

          <DevicesMap>
            <RootRef rootRef={this.container}>
              {isLoading ? (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapsKey }}
                  center={center}
                  zoom={zoom}
                  defaultCenter={mapCenter}
                  defaultZoom={defaultZoom.noDevice}
                />
              ) : (
                <DeviceClusteringMap locations={locations} />
              )}
            </RootRef>
          </DevicesMap>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  globalState: state.globalState,
  globalMap: state.locations,
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getLocations: (height: number, width: number) => {
      dispatch(getLocations(height, width));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(Dashboard));
