import { IDeviceDownlink, initialDeviceDownlink } from "../models/IDownlinks";
import { ActionType } from "../../../core/ActionTypes";

export type deviceDownlinkReducerType =
  | { type: ActionType.DEVICE_DOWNLINK_LOADING; payload: boolean }
  | {
      type: ActionType.SET_DEVICE_DOWNLINK_FORM_VALUE;
      payload: IDeviceDownlink;
    }
  | { type: ActionType.CLEAR_DEVICE_DOWNLINK }
  | { type: ActionType.GET_EMPTY_DEVICE_DOWNLINK; payload: IDeviceDownlink }
  | {
      type: ActionType.SET_DEVICE_OF_DOWNLINK_FORMAT;
      payload: IDeviceDownlink;
    };

export function deviceDownlinkReducer(
  state: IDeviceDownlink = { ...initialDeviceDownlink },
  action: deviceDownlinkReducerType
): IDeviceDownlink {
  switch (action.type) {
    case ActionType.DEVICE_DOWNLINK_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;

    case ActionType.SET_DEVICE_DOWNLINK_FORM_VALUE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.CLEAR_DEVICE_DOWNLINK:
      state = {
        ...initialDeviceDownlink
      };
      break;
    case ActionType.GET_EMPTY_DEVICE_DOWNLINK:
      state = {
        ...state,
        ...action.payload,
        isLoading: state.isLoading
      };
      break;
    case ActionType.SET_DEVICE_OF_DOWNLINK_FORMAT:
      state = {
        ...state,
        ...action.payload
      };
      break;
    default:
      break;
  }
  return state;
}
