import {
  IDeviceAlertCollection,
  initialDeviceAlertCollection
} from "../models/IAlerts";
import { ActionType } from "../../../core/ActionTypes";

export type deviceAlertCollectionReducerTypes =
  | { type: ActionType.DEVICE_ALERT_COLLECTION_LOADING; payload: boolean }
  | {
      type: ActionType.GET_DEVICE_ALERT_COLLETION;
      payload: IDeviceAlertCollection;
    }
  | {
      type: ActionType.REINITIALIZE;
    };

export function deviceAlertCollectionReducer(
  state: IDeviceAlertCollection = { ...initialDeviceAlertCollection },
  action: deviceAlertCollectionReducerTypes
): IDeviceAlertCollection {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = { ...initialDeviceAlertCollection };
      break;
    case ActionType.DEVICE_ALERT_COLLECTION_LOADING:
      state = {
        ...state,
        ...(action.payload && initialDeviceAlertCollection),
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_ALERT_COLLETION:
      state = {
        ...state,
        ...action.payload
      };
      break;
    default:
      break;
  }
  return state;
}
