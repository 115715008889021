import {
  CanadianCodes,
  countryCodes,
  ICountryCode
} from "../configuration/countryCodes";

export const getPhoneCode = (phoneNumber: string): ICountryCode | undefined => {
  let codes = [...countryCodes];
  if (phoneNumber.charAt(0) === "1" && phoneNumber.charAt(1) !== "-") {
    return CanadianCodes.filter(canCode =>
      new RegExp(`^1${canCode}`).test(phoneNumber)
    ).length > 0
      ? codes.filter(country => country.country === "Canada").pop()
      : codes.filter(country => country.country === "United States").pop();
  }
  if (phoneNumber.charAt(1) === "-") {
    codes = codes.filter(country => country.code !== "1");
  }
  return codes
    .filter(country => new RegExp(`^${country.code}`).test(phoneNumber))
    .pop();
};
export const getUserLocalePhoneCode = (): ICountryCode => {
  const language = // tslint:disable-next-line
    window.navigator["userLanguage"] || window.navigator.language;
  const currentCountry = countryCodes
    .filter(
      country =>
        country.iso
          .split(" / ")
          .filter(
            iso => iso.toUpperCase() === language.split("-")[1].toUpperCase()
          ).length > 0
    )
    .pop();
  if (currentCountry) {
    return currentCountry;
  }
  return countryCodes.filter(country => country.country === "Australia")[0];
};
export const trimPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber && phoneNumber.length > 0) {
    return phoneNumber.replace(/ /g, "").replace(/^0+/, "");
  }
  return "";
};
export const trimPhoneCountryCode = (phone: string) => {
  const phoneCountry = getPhoneCode(phone.replace("+", ""));
  if (phoneCountry) {
    return phone.replace("+", "").slice(phoneCountry.code.length);
  }
  return phone;
};
