import * as React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import styled from "styled-components";

import {
  NoStyleLink,
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import { theme } from "../core/withThemeProvider";

export const AppDownloadImg = styled.img`
  height: 80px;
`;
AppDownloadImg.displayName = "AppDownloadImg";

export const AppDownloadImgContainer = styled(Grid)`
  padding: ${theme.spacing(4)}px 0;
`;
AppDownloadImgContainer.displayName = "AppDownloadImgContainer";

export const InstallMobileApp: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <StyledTitle />
      <StyledBackground>
        <Typography variant="h5" align="center">
          Please try our mobile application
        </Typography>
        <AppDownloadImgContainer container={true} justify="space-around">
          <NoStyleLink to="/">
            <AppDownloadImg src="/img/appstore.png" alt="appstore" />
          </NoStyleLink>
          <NoStyleLink to="/">
            <AppDownloadImg src="/img/playmarket.png" alt="google play" />
          </NoStyleLink>
        </AppDownloadImgContainer>
        <Typography variant="caption" align="center">
          Please contact us if you have any issues at{" "}
          <Link color="primary" href="mailto:support@ellenex.com">
            support@ellenex.com
          </Link>
        </Typography>
      </StyledBackground>
    </React.Fragment>
  );
};
InstallMobileApp.displayName = "InstallMobileApp";
