import * as FileSaver from "file-saver";

import { IExportDevice } from "../models/IExportDevice";
import { IDeviceCollection } from "../../Devices/models/IDevice";

export const addDevicesForExport = (
  exportDevice: IExportDevice,
  deviceCollection: IDeviceCollection
): IExportDevice => {
  return {
    ...exportDevice,
    deviceList: deviceCollection.members,
    device:
      deviceCollection.members.length === 1
        ? deviceCollection.members[0]
        : exportDevice.device
  };
};

export const saveExportToFile = (
  encodedDeviceData: string,
  serial: string,
  name: string
) => {
  const blob = new Blob([encodedDeviceData], { type: "application/csv" });
  FileSaver.saveAs(blob, `${serial} - ${name}.csv`);
};
