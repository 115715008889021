import React from "react";
import { connect } from "react-redux";
import { ChangeEvent } from "react";
import {
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Fab,
  withWidth,
  Paper
} from "@material-ui/core";
import { isWidthDown, WithWidth } from "@material-ui/core/withWidth";
import { Refresh as RefreshIcon, Add as AddIcon } from "@material-ui/icons";
import styled from "styled-components";

import { IRootState } from "../core/store";
import { withThemeProvider } from "../core/withThemeProvider";
import { IChartQueryResult, IDeviceCollection } from "./models/IDevice";
import {
  getDeviceCollection,
  changeDeviceCollectionPage,
  changeMeasurementPeriod,
  getCollectionMeasurementsChart,
  getCollectionMeasurements,
  getChartAlertLevels
} from "./actions/deviceCollectionAction";
import { DeviceTable } from "./components/DeviceTable";
import { Configuration } from "../core/configuration/config";
import { DeviceList } from "./components/DeviceList";
import {
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { IInfluxTableQuery } from "../Resources/models/IResource";
import { IDeviceAlertApi } from "./DeviceAlerts/models/IAlerts";
import { deepLinkRedirect } from "../core/utilities/ServiceUtilities";
import { IGlobalState } from "../models/IGlobalState";

export const PeriodSelectorForm = styled.form`
  display: flex;
  justify-content: flex-end;
  padding-right: 51px;
  & > div {
    width: 200px;
  }
`;
PeriodSelectorForm.displayName = "PeriodSelectorForm";

export const PeriodSelectorContainer = styled(Paper)`
  && {
    position: sticky;
    top: 60px;
    padding: 15px 10px;
    background: white;
    z-index: 1;
    margin-bottom: -6px;
    width: 100%;
  }
`;
PeriodSelectorContainer.displayName = "PeriodSelectorContainer";

export const PeriodSelectorContainerMobile = styled(PeriodSelectorContainer)`
  && {
    text-align: right;
  }
`;
PeriodSelectorContainerMobile.displayName = "PeriodSelectorContainerMobile";

export const RefreshButton = styled(Button)`
  && {
    margin-left: 10px;
  }
  align-self: flex-end;
  svg {
    margin-right: 10px;
  }
`;
RefreshButton.displayName = "RefreshButton";

export const AddDeviceFAB = styled(Fab)`
  && {
    position: fixed;
    bottom: 100px;
    right: 50px;
  }
`;
AddDeviceFAB.displayName = "AddDeviceFAB";

interface IDevicesStateProps {
  deviceCollection: IDeviceCollection;
  globalState: IGlobalState;
}

interface IDevicesDispatchProps {
  getCollection: () => Promise<IDeviceCollection | SnackbarError>;
  changePage: (page: string) => Promise<IDeviceCollection | SnackbarError>;
  changePeriod: (
    period: string,
    property: string,
    deviceCollection: IDeviceCollection
  ) => void;
  getChart: (
    deviceCollection: IDeviceCollection,
    property: string,
    period: string
  ) => Promise<IChartQueryResult[] | SnackbarError>;
  getAlerts: (
    collection: IDeviceCollection
  ) => Promise<IDeviceAlertApi[] | SnackbarError>;
  getMeasurements: (
    deviceCollection: IDeviceCollection
  ) => Promise<IInfluxTableQuery[] | SnackbarError>;
}

export class Devices extends React.Component<
  IDevicesStateProps & IDevicesDispatchProps & WithSnackbarProps & WithWidth
> {
  public fetchDevices = async (page?: string) => {
    const {
      changePage,
      getCollection,
      getMeasurements,
      getChart,
      getAlerts,
      deviceCollection,
      changePeriod,
      enqueueSnackbar
    } = this.props;
    const { defaultMeasurementParameter } = Configuration.devices;
    let collection: IDeviceCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      collection = await getCollection();
    }
    if (collection) {
      if (isSnackbarError(collection)) {
        enqueueSnackbar(collection.message, collection.options);
      } else {
        changePeriod(
          deviceCollection.measurementPeriod,
          defaultMeasurementParameter,
          collection
        );
        const [alerts, measurements, chart] = await Promise.all([
          getAlerts(collection),
          getMeasurements(collection),
          getChart(
            collection,
            defaultMeasurementParameter,
            deviceCollection.measurementPeriod
          )
        ]);
        if (isSnackbarError(alerts)) {
          enqueueSnackbar(alerts.message, alerts.options);
        }
        if (isSnackbarError(measurements)) {
          enqueueSnackbar(measurements.message, measurements.options);
        }
        if (isSnackbarError(chart)) {
          enqueueSnackbar(chart.message, chart.options);
        }
      }
    }
  };

  public changePage = (page: string) => {
    this.fetchDevices(page);
  };

  public refreshPage = () => {
    this.fetchDevices(this.props.deviceCollection.view["@id"]);
  };

  public async componentDidMount() {
    this.fetchDevices();
  }

  public changeMeasurementPeriod = async (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const {
      enqueueSnackbar,
      changePeriod,
      getChart,
      deviceCollection
    } = this.props;
    if (event.target.name) {
      changePeriod(
        event.target.value as string,
        event.target.name,
        deviceCollection
      );
      const chart = await getChart(
        deviceCollection,
        event.target.name,
        event.target.value as string
      );

      if (isSnackbarError(chart)) {
        enqueueSnackbar(chart.message, chart.options);
      }
    }
  };

  public openMobileApp = (url: string) => () => {
    deepLinkRedirect(url);
  };

  public render() {
    const {
      deviceCollection: {
        measurementPeriod,
        isLoading,
        totalItems,
        members,
        view
      },
      width,
      globalState: { noHeader }
    } = this.props;
    const { devices } = Configuration;

    return (
      <React.Fragment>
        <StyledTitle>Devices</StyledTitle>
        <Hidden smDown={true}>
          <PeriodSelectorContainer>
            <PeriodSelectorForm>
              <FormControl>
                <InputLabel htmlFor="period">Duration</InputLabel>
                <Select
                  value={measurementPeriod}
                  onChange={this.changeMeasurementPeriod}
                  inputProps={{
                    name: devices.defaultMeasurementParameter,
                    id: "period"
                  }}
                >
                  {devices.measurementPeriods.map((period, index) => (
                    <MenuItem key={index} value={period.value}>
                      {period.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RefreshButton onClick={this.refreshPage}>
                <RefreshIcon /> Refresh
              </RefreshButton>
            </PeriodSelectorForm>
          </PeriodSelectorContainer>
        </Hidden>
        <Hidden mdUp={true}>
          <PeriodSelectorContainerMobile>
            <RefreshButton onClick={this.refreshPage}>
              <RefreshIcon /> Refresh
            </RefreshButton>
          </PeriodSelectorContainerMobile>
        </Hidden>
        <StyledBackground padding="10px 15px 0" overflow="auto">
          <Hidden smDown={true}>
            <DeviceTable
              isLoading={isLoading}
              pagination={view}
              rows={members}
              totalItems={totalItems}
              onChangePage={this.changePage}
            />
          </Hidden>
          <Hidden mdUp={true}>
            <DeviceList
              isLoading={isLoading}
              rows={members}
              totalItems={totalItems}
              pagination={view}
              onChangePage={this.fetchDevices}
            />
          </Hidden>
        </StyledBackground>
        {!noHeader && (
          <AddDeviceFAB
            onClick={this.openMobileApp("/devices/add")}
            color="primary"
            aria-label="add"
            variant={isWidthDown("sm", width) ? "round" : "extended"}
          >
            <AddIcon />
            <Hidden smDown={true}>Add Device</Hidden>
          </AddDeviceFAB>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  deviceCollection: state.deviceCollection,
  globalState: state.globalState
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    getCollection: async () => dispatch(getDeviceCollection()),
    changePage: (page: string) => dispatch(changeDeviceCollectionPage(page)),
    changePeriod: (period: string) => {
      dispatch(changeMeasurementPeriod(period));
    },
    getChart: (
      deviceCollection: IDeviceCollection,
      property: string,
      period: string
    ) =>
      dispatch(
        getCollectionMeasurementsChart(deviceCollection, property, period)
      ),
    getAlerts: (collection: IDeviceCollection) =>
      dispatch(getChartAlertLevels(collection)),
    getMeasurements: (deviceCollection: IDeviceCollection) =>
      dispatch(getCollectionMeasurements(deviceCollection))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(withWidth()(Devices))));
