export enum ActionType {
  // device
  DEVICE_LOADING = "DEVICE_LOADING",
  GET_DEVICE = "GET_DEVICE",
  UPDATE_DEVICE = "UPDATE_DEVICE",
  CLEAR_DEVICE = "CLEAR_DEVICE",

  DEVICE_MEASUREMENT_LOADING = "DEVICE_MEASUREMENT_LOADING",
  GET_DEVICE_MEASUREMENTS = "GET_DEVICE_MEASUREMENTS",

  // device collection
  DEVICE_COLLECTION_LOADING = "DEVICE_COLLECTION_LOADING",
  GET_DEVICE_COLLECTION = "GET_DEVICE_COLLECTION",
  CHANGE_DEVICE_COLLECTION_PAGE = "CHANGE_DEVICE_COLLECTION_PAGE",

  CHANGE_DEVICE_COLLECTION_MEASUREMENT_PERIOD = "CHANGE_DEVICE_COLLECTION_MEASUREMENT_PERIOD",

  COLLECTION_MEASUREMENTS_LOADING = "COLLECTION_MEASUREMENTS_LOADING",
  GET_DEVICE_COLLECTION_MEASUREMENTS = "GET_DEVICE_COLLECTION_MEASUREMENTS",
  COLLECTION_CHART_LOADING = "COLLECTION_CHART_LOADING",
  GET_DEVICE_COLLECTION_CHART = "GET_DEVICE_COLLECTION_CHART",
  GET_COLLECTION_CHART_THRESHOLDS = "GET_COLLECTION_CHART_THRESHOLDS",

  // device parameters
  DEVICE_PARAMETERS_LOADING = "DEVICE_PARAMETERS_LOADING",
  GET_DEVICE_PARAMETERS = "GET_DEVICE_PARAMETERS",
  DEVICE_PARAMETERS_CHART_LOADING = "DEVICE_PARAMETERS_CHART_LOADING",
  REFRESH_DEVICE_PARAMETER_OPTIONS = "REFRESH_DEVICE_PARAMETER_OPTIONS",
  CLEAR_VIEW_DEVICE_PARAMETER = "CLEAR_VIEW_DEVICE_PARAMETER",
  DEVICE_PARAMETER_EXPANSION_STATE = "DEVICE_PARAMETER_EXPANSION_STATE",

  // device alerts
  DEVICE_ALERT_COLLECTION_LOADING = "DEVICE_ALERT_COLLECTION_LOADING",
  GET_DEVICE_ALERT_COLLETION = "GET_DEVICE_ALERT_COLLETION",
  CHANGE_DEVICE_ALERT_COLLECTION_PAGE = "CHANGE_DEVICE_ALERT_COLLECTION_PAGE",
  DEVICE_ALERT_LOADING = "DEVICE_ALERT_LOADING",
  GET_DEVICE_ALERT = "GET_DEVICE_ALERT",
  GET_EMPTY_DEVICE_ALERT = "GET_EMPTY_DEVICE_ALERT",
  UPDATE_DEVICE_ALERT = "UPDATE_DEVICE_ALERT",
  CREATE_DEVICE_ALERT = "CREATE_DEVICE_ALERT",
  CLEAR_DEVICE_ALERT = "CLEAR_DEVICE_ALERT",
  DELETE_DEVICE_ALERT_DIALOG = "DELETE_DEVICE_ALERT_DIALOG",
  SET_DEVICE_ALERT_FORM_VALUE = "SET_DEVICE_ALERT_FORM_VALUE",
  SET_DEVICE_ALERT_NOTIFICATION_KIND = "SET_DEVICE_ALERT_NOTIFICATION_KIND",

  // device downlinks
  DEVICE_DOWNLINK_COLLECTION_LOADING = "DEVICE_DOWNLINK_COLLECTION_LOADING",
  GET_DEVICE_DOWNLINK_COLLECTION = "GET_DEVICE_DOWNLINK_COLLECTION",
  CLEAR_DEVICE_DOWNLINK_COLLECTION = "CLEAR_DEVICE_DOWNLINK_COLLECTION",
  DEVICE_DOWNLINK_LOADING = "DEVICE_DOWNLINK_LOADING",
  SET_DEVICE_DOWNLINK_FORM_VALUE = "SET_DEVICE_DOWNLINK_FORM_VALUE",
  CLEAR_DEVICE_DOWNLINK = "CLEAR_DEVICE_DOWNLINK",
  SET_DEVICE_OF_DOWNLINK = "SET_DEVICE_OF_DOWNLINK",
  GET_EMPTY_DEVICE_DOWNLINK = "GET_EMPTY_DEVICE_DOWNLINK",
  SET_DEVICE_OF_DOWNLINK_FORMAT = "SET_DEVICE_OF_DOWNLINK_FORMAT",

  // user profile
  USER_PROFILE_LOADING = "USER_PROFILE_LOADING",
  GET_USER_PROFILE = "GET_USER_PROFILE",
  GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES = "GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES",
  UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE",

  // export device
  EXPORT_INITIALIZING = "EXPORT_INITIALIZING",
  EXPORT_INITIALIZED = "EXPORT_INITIALIZED",
  EXPORT_DEVICE = "EXPORT_DEVICE",

  // authentication
  AUTHENTICATION = "AUTHENTICATION",
  LOGOUT = "LOGOUT",

  // locations
  DEVICE_LOCATIONS_LOADING = "DEVICE_LOCATIONS_LOADING",
  GET_DEVICE_LOCATIONS = "GET_DEVICE_LOCATIONS",
  DEVICE_LOCATIONS_HISTORY_LOADING = "DEVICE_LOCATIONS_HISTORY_LOADING",
  GET_DEVICE_LOCATIONS_HISTORY = "GET_DEVICE_LOCATIONS_HISTORY",

  // resources
  GET_RESOURCES = "GET_RESOURCES",
  GET_RESOURCE_MEASUREMENTS = "GET_RESOURCE_MEASUREMENTS",
  RESOURCE_TAB_STATE = "RESOURCE_TAB_STATE",
  INITIALIZE_RESOURCE_MEASUREMENTS = "INITIALIZE_RESOURCE_MEASUREMENTS",

  // utility
  TOOLBAR_MENU_CHANGE_STATE = "TOOLBAR_MENU_CHANGE_STATE",
  OPEN_MENU_CHANGE = "OPEN_MENU_CHANGE",
  LOGIN_ERROR = "LOGIN_ERROR",
  GET_CONNECTED_DEVICES = "GET_CONNECTED_DEVICES",
  REFRESH_REQUIRED = "REFRESH_REQUIRED",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  NO_HEADER = "NO_HEADER",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  REINITIALIZE = "REINITIALIZE"
}
