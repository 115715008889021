import React from "react";
import styled, { keyframes } from "styled-components";

import { theme } from "../../core/withThemeProvider";
import { IGeoClusterClick, IGeolocation } from "../models/IGeolocation";

export const pulsate = keyframes`
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    filter: none;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    filter: alpha(opacity=0);
  }
`;

export const bounce = keyframes`
 0% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translateY(-2000px) rotate(-45deg);
  }
  10% {
    opacity: 1;
    filter: none;
    transform: translateY(30px) rotate(-45deg);
  }
  40% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Pulse = styled.div`
  background: ${theme.palette.primary.light};
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0 0 -12px;
  transform: rotateX(55deg);
  ::after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: ${pulsate} 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    filter: alpha(opacity=0);
    box-shadow: 0 0 1px 5px ${theme.palette.primary.light};
  }
`;
Pulse.displayName = "Pulse";

export const Pin = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: ${theme.palette.primary.main};
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: ${fadeIn};
  animation-timing-function: linear;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  z-index: 1;
  ::after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: ${theme.palette.primary.contrastText};
    position: absolute;
    border-radius: 50%;
  }
`;
Pin.displayName = "Pin";

export const PinContainer = styled.span<IGeoClusterClick>`
  cursor: ${props =>
    props.cluster && props.showCluster ? "pointer" : "inherit"};
`;
PinContainer.displayName = "PinContainer";

export const DevicePin: React.FunctionComponent<IGeolocation &
  IGeoClusterClick> = ({ showCluster, cluster }) => (
  <PinContainer
    cluster={cluster}
    showCluster={showCluster}
    onClick={cluster && showCluster && showCluster(cluster)}
  >
    <Pin />
    <Pulse />
  </PinContainer>
);
DevicePin.displayName = "DevicePin";

export const ClusterPin = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: ${theme.palette.primary.contrastText};
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.85rem;
  text-align: center;
  z-index: 1;
  opacity: 0.85;
  animation: ${fadeIn} 0.5s ease-in-out;
  ::before {
    content: "";
    border-radius: 50%;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
    position: absolute;
    animation: ${fadeIn} 1s ease-in-out;
    box-shadow: 0 0 3px 7px ${theme.palette.primary.light};
  }
  :hover {
    background-color: #d4d4d4;
    cursor: pointer;
  }
`;
ClusterPin.displayName = "ClusterPin";

export const DeviceClusterPin: React.FunctionComponent<IGeolocation &
  IGeoClusterClick> = ({ cluster, children, showCluster }) => (
  <span onClick={cluster && showCluster && showCluster(cluster)}>
    <ClusterPin>{children}</ClusterPin>
  </span>
);
ClusterPin.displayName = "ClusterPin";
