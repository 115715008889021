import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Formik } from "formik";
import { Grid, Button } from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../../core/store";
import { withThemeProvider } from "../../core/withThemeProvider";
import {
  NoStyleLink,
  StyledTitle,
  StyledTitleButtonGrid
} from "../../components/sharedStyledComponents";
import {
  clearDeviceDownlink,
  createDownlinkByDeviceId,
  setDeviceOfDownlink,
  setDeviceDownlinkFormatInitial
} from "./actions/downlinkAction";
import { IDeviceDownlink, ISeparatedDownlinkFormat } from "./models/IDownlinks";
import { EditDeviceDownlinkForm } from "./components/EditDeviceDownlinkForm";
import { StyledListIcon } from "../EditDevice";
import { IDevice } from "../models/IDevice";
import {
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions,
  snackbarMessages
} from "../../core/utilities/SnackbarUtilities";

interface IPathParamsType {
  id: string;
}

interface IDeviceDownlinkStateProps {
  deviceDownlink: IDeviceDownlink;
  device: IDevice;
}

interface IDeviceDownlinkDispatchProps {
  createDeviceDownlink: (
    id: string,
    downlink: IDeviceDownlink
  ) => Promise<IDeviceDownlink | SnackbarError>;
  clearDeviceDownlink: () => void;
  setDeviceDownlink: (id: string) => Promise<IDevice | SnackbarError>;
  setDownlinkFormatInitial: (
    downlink: IDeviceDownlink,
    formats: ISeparatedDownlinkFormat[]
  ) => void;
}

type EditDeviceDownlinkProps = IDeviceDownlinkStateProps &
  IDeviceDownlinkDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditDeviceDownlink extends React.Component<
  EditDeviceDownlinkProps
> {
  public async componentDidMount() {
    const {
      setDeviceDownlink,
      match: {
        params: { id }
      },
      enqueueSnackbar
    } = this.props;

    const deviceDownlinkResult = await setDeviceDownlink(id);

    if (isSnackbarError(deviceDownlinkResult)) {
      enqueueSnackbar(
        deviceDownlinkResult.message,
        deviceDownlinkResult.options
      );
    }
  }

  public componentWillUnmount() {
    this.props.clearDeviceDownlink();
  }

  public onSubmit = async (values: IDeviceDownlink) => {
    const {
      createDeviceDownlink,
      match: {
        params: { id }
      },
      history,
      enqueueSnackbar
    } = this.props;
    if (values) {
      const result = await createDeviceDownlink(id, values);

      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else {
        enqueueSnackbar(
          snackbarMessages.DEVICE_DOWNLINK_CREATE_SUCCESS,
          getStyledSnackbarOptions("success")
        );
        history.push(`/devices/${id}/downlinks`);
      }
      return;
    }
  };

  public downlinkFormatInitial = (
    downlink: IDeviceDownlink,
    formats: ISeparatedDownlinkFormat[]
  ) => {
    const { setDownlinkFormatInitial } = this.props;
    setDownlinkFormatInitial(downlink, formats);
  };

  public render() {
    const {
      deviceDownlink,
      match: {
        params: { id }
      },
      device
    } = this.props;

    return (
      <Grid container={true} item={true} xs={12} md={9} lg={8} xl={8}>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <StyledTitleButtonGrid
            container={true}
            item={true}
            justify="flex-end"
            alignItems="center"
          >
            <StyledTitleButtonGrid item={true}>
              {`Send Downlink To Device "${device.name}"`}
            </StyledTitleButtonGrid>
            <NoStyleLink to={`/devices/${id}/downlinks`}>
              <Button color="default">
                <StyledListIcon />
                Downlink List
              </Button>
            </NoStyleLink>
          </StyledTitleButtonGrid>
        </StyledTitle>

        <Grid item={true} xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={deviceDownlink}
            onSubmit={this.onSubmit}
          >
            {props => (
              <EditDeviceDownlinkForm
                {...props}
                device={device}
                downlinkFormatInitial={this.downlinkFormatInitial}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: IRootState): IDeviceDownlinkStateProps => ({
  deviceDownlink: state.deviceDownlink,
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDeviceDownlinkDispatchProps => {
  return {
    createDeviceDownlink: (
      id: string,
      downlink: IDeviceDownlink
    ): Promise<IDeviceDownlink | SnackbarError> => {
      return dispatch(createDownlinkByDeviceId(id, downlink));
    },
    clearDeviceDownlink: () => {
      dispatch(clearDeviceDownlink());
    },
    setDeviceDownlink: (id: string): Promise<IDevice | SnackbarError> => {
      return dispatch(setDeviceOfDownlink(id));
    },
    setDownlinkFormatInitial: (
      downlink: IDeviceDownlink,
      formats: ISeparatedDownlinkFormat[]
    ) => {
      return dispatch(setDeviceDownlinkFormatInitial(downlink, formats));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditDeviceDownlink)));
