import * as React from "react";
import styled from "styled-components";
import { TooltipProps } from "recharts";

import { chartUnitToValueType } from "../../services/DeviceService";

export const TooltipContainer = styled.div`
  background: white;
  border-radius: 7px;
  border: 1px solid rgba(137, 132, 155, 0.3);
  padding: 5px 15px;
  color: black;
  opacity: 0.8;
  & > p {
    margin: 5px;
  }
`;
TooltipContainer.displayName = "DeviceTableTooltipContainer";
interface IValueString {
  color?: string;
}
export const ValueString = styled.p<IValueString>`
  text-align: center;
  color: ${props => props.color};
`;
ValueString.displayName = "DeviceTableValueString";

export const DeviceTableTooltip: React.FunctionComponent<TooltipProps> = ({
  active,
  payload,
  label
}) => {
  if (active && payload) {
    return (
      <TooltipContainer>
        <p>{label}</p>
        {payload.map((tooltipPayload, index) => {
          return (
            <ValueString
              key={index}
              color={tooltipPayload.color}
            >{`${chartUnitToValueType(
              tooltipPayload.name,
              tooltipPayload.payload.property
            )}: ${tooltipPayload.value} ${tooltipPayload.unit}`}</ValueString>
          );
        })}
      </TooltipContainer>
    );
  }
  return null;
};
DeviceTableTooltip.displayName = "DeviceTableTooltip";
