import { ActionType } from "../../../core/ActionTypes";
import {
  initialViewDeviceParameters,
  IParameterUnits,
  IViewDeviceParameters
} from "../models/IViewDeviceParameters";
import { Configuration } from "../../../core/configuration/config";

export type viewDeviceParametersReducerTypes =
  | {
      type: ActionType.DEVICE_PARAMETERS_LOADING;
      payload: boolean;
    }
  | {
      type: ActionType.GET_DEVICE_PARAMETERS;
      payload: {
        device: string;
        parameters: {
          parameterUnits: IParameterUnits;
          parameterList: string[];
        };
      };
    }
  | {
      type: ActionType.DEVICE_PARAMETERS_CHART_LOADING;
      payload: boolean;
    }
  | {
      type: ActionType.REFRESH_DEVICE_PARAMETER_OPTIONS;
      payload: IViewDeviceParameters;
    }
  | {
      type: ActionType.CLEAR_VIEW_DEVICE_PARAMETER;
    }
  | {
      type: ActionType.DEVICE_PARAMETER_EXPANSION_STATE;
    }
  | {
      type: ActionType.REINITIALIZE;
    };

export function deviceParametersReducer(
  state: IViewDeviceParameters = { ...initialViewDeviceParameters },
  action: viewDeviceParametersReducerTypes
): IViewDeviceParameters {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialViewDeviceParameters
      };
      break;
    case ActionType.DEVICE_PARAMETER_EXPANSION_STATE:
      state = {
        ...state,
        panelExpanded: !state.panelExpanded
      };
      break;
    case ActionType.CLEAR_VIEW_DEVICE_PARAMETER:
      state = {
        ...initialViewDeviceParameters
      };
      break;
    case ActionType.DEVICE_PARAMETERS_CHART_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.REFRESH_DEVICE_PARAMETER_OPTIONS:
      state = {
        ...state,
        ...action.payload,
        panelExpanded: false
      };
      break;
    case ActionType.DEVICE_PARAMETERS_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_PARAMETERS:
      const { deviceMainMeasuredParameters } = Configuration;
      const {
        parameters: { parameterList, parameterUnits },
        device
      } = action.payload;
      const parameter = deviceMainMeasuredParameters.reduceRight((acc, cur) => {
        const filteredParameter = parameterList
          .filter(v => v.toLowerCase() === cur.toLowerCase())
          .pop();
        return filteredParameter || acc;
      }, state.parameter);
      state = {
        ...state,
        parameterUnits,
        parameter,
        parameterList,
        device
      };
      break;
    default:
      break;
  }
  return state;
}
