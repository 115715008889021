import React from "react";
import { LinearProgress, Tooltip } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import {
  StyledBatteryDangerIcon,
  StyledBatteryNormalIcon
} from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";

export const BatteryCell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  const {
    threshold: { battery, time }
  } = Configuration;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values?.chargeLevel?.value && (
            <Tooltip
              title={`Battery: ${values.chargeLevel.value} ${values.chargeLevel.unitText}`}
            >
              <span>
                {values.chargeLevel.value < battery && (
                  <StyledBatteryDangerIcon
                    timeout={values.lastSeen ? time(values.lastSeen) : true}
                  />
                )}
                {values.chargeLevel.value > battery && (
                  <StyledBatteryNormalIcon
                    timeout={values.lastSeen ? time(values.lastSeen) : true}
                  />
                )}
              </span>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
