import * as React from "react";
import { Form, FormikProps } from "formik";
import { Button, Grid, LinearProgress } from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { Validators } from "../../util/validators";
import { IAddNewDevice } from "../models/IAddNewDevice";

export const AddNewDeviceForm: React.FunctionComponent<FormikProps<
  IAddNewDevice
>> = ({ submitForm, isSubmitting }: FormikProps<IAddNewDevice>) => {
  const { transferCode, serial } = Validators.addNewDevice;

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Serial"
        name="serial"
        validate={serial}
        disabled={isSubmitting}
        component={TextField}
      />
      <br />
      <StyledTextField
        type="text"
        label="Transfer Code"
        name="transferCode"
        validate={transferCode}
        disabled={isSubmitting}
        component={TextField}
      />
      <br />
      {isSubmitting && <LinearProgress />}
      <br />
      <Grid container={true} item={true} justify="flex-start">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          Add
        </Button>
      </Grid>
    </Form>
  );
};
AddNewDeviceForm.displayName = "AddNewDeviceForm";
