import {
  IDeviceDownlinkCollection,
  initialDeviceDownlinkCollection
} from "../models/IDownlinks";
import { ActionType } from "../../../core/ActionTypes";

export type deviceDownlinkCollectionReducerTypes =
  | { type: ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING; payload: boolean }
  | {
      type: ActionType.GET_DEVICE_DOWNLINK_COLLECTION;
      payload: IDeviceDownlinkCollection;
    }
  | {
      type: ActionType.CLEAR_DEVICE_DOWNLINK_COLLECTION;
    };

export function deviceDownlinkCollectionReducer(
  state: IDeviceDownlinkCollection = { ...initialDeviceDownlinkCollection },
  action: deviceDownlinkCollectionReducerTypes
): IDeviceDownlinkCollection {
  switch (action.type) {
    case ActionType.DEVICE_DOWNLINK_COLLECTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_DOWNLINK_COLLECTION:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.CLEAR_DEVICE_DOWNLINK_COLLECTION:
      state = {
        ...initialDeviceDownlinkCollection
      };
      break;
    default:
      break;
  }
  return state;
}
