import { ActionType } from "../../core/ActionTypes";
import {
  IUserProfile,
  initialUserProfile,
  ISubscriptionWithRole
} from "../models/IUserProfile";

export type userProfileReducerTypes =
  | { type: ActionType.GET_USER_PROFILE; payload: IUserProfile }
  | {
      type: ActionType.GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES;
      payload: ISubscriptionWithRole[];
    }
  | { type: ActionType.USER_PROFILE_LOADING; payload: boolean }
  | { type: ActionType.UPDATE_USER_PROFILE; payload: IUserProfile };

export function userProfileReducer(
  state: IUserProfile = { ...initialUserProfile },
  action: userProfileReducerTypes
): IUserProfile {
  switch (action.type) {
    case ActionType.GET_USER_PROFILE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES:
      state = {
        ...state,
        hasRoles: action.payload
      };
      break;
    case ActionType.UPDATE_USER_PROFILE:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.USER_PROFILE_LOADING:
      state = {
        ...state,
        isLoading: action.payload,
        name: !action.payload && !state.name ? "UNKNOWN USER" : state.name
      };
      break;
    default:
      break;
  }
  return state;
}
