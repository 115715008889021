import React from "react";
import { LinearProgress, Tooltip } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import {
  StyledRSSIErrorIcon,
  StyledRSSIHighSignalIcon,
  StyledRSSILowSignalIcon,
  StyledRSSIMidSignalIcon,
  StyledRSSINoSignalIcon
} from "../../../components/RSSIIcon";
import { Configuration } from "../../../core/configuration/config";

export const RSSICell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  const rssi = values && values.rssi ? values.rssi.value : null;
  const rssiUnit = values && values.rssi ? values.rssi.unitText : null;
  const timeout =
    values && values.lastSeen
      ? Configuration.threshold.time(values.lastSeen)
      : true;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Tooltip title={`RSSI: ${rssi} ${rssiUnit}`}>
          <span>
            {rssi && rssi < 99 && rssi > 31 && (
              <StyledRSSIErrorIcon timeout={timeout} />
            )}
            {rssi && rssi <= 31 && rssi >= 15 && (
              <StyledRSSIHighSignalIcon timeout={timeout} />
            )}
            {rssi && rssi < 15 && rssi >= 10 && (
              <StyledRSSIMidSignalIcon timeout={timeout} />
            )}
            {rssi && rssi < 10 && <StyledRSSILowSignalIcon timeout={timeout} />}
            {rssi && rssi === 99 && (
              <StyledRSSINoSignalIcon timeout={timeout} />
            )}
          </span>
        </Tooltip>
      )}
    </CustomizedTableCell>
  );
};
