import React from "react";
import moment from "moment";
import { FieldProps } from "formik";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { Configuration } from "../core/configuration/config";

export type IDatePickerProps = FieldProps & KeyboardDatePickerProps;

export const FormikDatePicker: React.ComponentType<IDatePickerProps> = ({
  form: { setFieldError, setFieldValue, errors },
  field: { name, value },
  ...other
}: IDatePickerProps) => {
  const { format, mask, maskChar } = Configuration.datepicker;
  const sfv = (date: MaterialUiPickersDate) => {
    if (date) {
      setFieldValue(name, date ? date : moment());
    }
  };
  const currentError = errors[name];
  const setError = (error: React.ReactNode) => {
    if (error !== currentError) {
      setFieldError(name, error as any);
    }
  };
  return (
    <KeyboardDatePicker
      autoOk={true}
      name={name}
      value={value}
      format={format}
      mask={mask}
      maskChar={maskChar}
      helperText={currentError}
      error={Boolean(currentError)}
      onError={setError}
      onChange={sfv}
      {...other}
    />
  );
};
FormikDatePicker.displayName = "FormikDatePicker";
