import * as React from "react";
import styled from "styled-components";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItem
} from "@material-ui/core";
import { ArrowDropDown, Menu as MenuIcon } from "@material-ui/icons";

import { theme } from "../core/withThemeProvider";
import { NoStyleLink } from "./sharedStyledComponents";
import { Configuration } from "../core/configuration/config";

export const HeaderTitle = styled(Typography)`
  flex-grow: 1;
`;
HeaderTitle.displayName = "HeaderTitle";

export const HeaderMenuButton = styled(IconButton)`
  && {
    margin-left: -12px;
    margin-right: 20px;
  }
`;
HeaderMenuButton.displayName = "HeaderMenuButton";

export const HeaderAppBar = styled(AppBar)`
  && {
    z-index: ${theme.zIndex.drawer + 1};
    position: fixed;
  }
`;
HeaderAppBar.displayName = "HeaderAppBar";

export const HeaderImage = styled.img`
  height: 40px;
  margin: 10px 10px 10px 0;
`;
HeaderImage.displayName = "HeaderImage";

export const SubRoleItem = styled(ListItemText)`
  && > * {
    color: ${theme.palette.secondary.main};
  }
`;

interface IProps {
  toolbarMenuOpen: any;
  handleClose: () => void;
  handleOpen: (e: any) => void;
  logout: () => void;
  menuClick: () => void;
  user?: string;
  userId?: string;
  subName?: string;
  roleName?: string;
}

export const Header: React.FunctionComponent<IProps> = ({
  toolbarMenuOpen,
  handleClose,
  userId,
  handleOpen,
  user,
  subName,
  roleName,
  logout,
  menuClick
}: IProps) => (
  <HeaderAppBar position="static">
    <Toolbar>
      <HeaderMenuButton color="inherit" aria-label="Menu" onClick={menuClick}>
        <MenuIcon />
      </HeaderMenuButton>
      <HeaderImage src={Configuration.logo} />
      <HeaderTitle variant="h6" color="inherit">
        {Configuration.companyName}
      </HeaderTitle>
      <Button
        aria-owns={toolbarMenuOpen ? "toolbar-menu" : undefined}
        aria-haspopup={true}
        color="inherit"
        data-type="toolbar-menu"
        onClick={handleOpen}
      >
        {`${user} ${roleName && `(${roleName})`}`}
        {user && roleName && <ArrowDropDown />}
      </Button>
      <Menu
        id="toolbar-menu"
        disableAutoFocusItem={true}
        anchorEl={toolbarMenuOpen}
        open={Boolean(toolbarMenuOpen)}
        onClose={handleClose}
      >
        <ListItem>
          <SubRoleItem
            primary={roleName ? roleName : "UNKNOWN ROLE"}
            secondary={"@" + (subName ? subName : "UNKNOWN SUBSCRIPTION")}
          />
        </ListItem>
        <NoStyleLink to={`/users/${userId}`}>
          <MenuItem divider={true} onClick={handleClose}>
            Switch Role
          </MenuItem>
        </NoStyleLink>
        <MenuItem onClick={logout}>Sign Out</MenuItem>
      </Menu>
    </Toolbar>
  </HeaderAppBar>
);
Header.displayName = "Header";
