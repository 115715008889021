import React from "react";
import { FieldArrayRenderProps, Field } from "formik";
import styled, { keyframes } from "styled-components";
import {
  Grid,
  IconButton,
  Tooltip,
  InputLabel,
  MenuItem,
  InputAdornment,
  FormControl,
  Button
} from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from "@material-ui/icons";

import { StyledTextField, TextField } from "../../../components/TextField";
import { Select, StyledSelect } from "../../../components/Select";
import { INotification } from "../models/ITemplate";
import { Validators } from "../../../util/validators";
import { Configuration } from "../../../core/configuration/config";
import {
  countryCodes,
  ICountryCode
} from "../../../core/configuration/countryCodes";
import { GreyPhoneCode } from "../../../Profile/components/EditUserProfileForm";
import {
  getUserLocalePhoneCode,
  trimPhoneNumber
} from "../../../core/utilities/PhoneUtilities";
import { DeviceAlertTestSMSMessage } from "./DeviceAlertTestMessage";

interface IActionButton {
  visibility: "hidden" | "visible";
}
export const ActionButton = styled(IconButton)<IActionButton>`
  && {
    height: 48px;
    color: #d3d3d3;
    visibility: ${props => props.visibility};
    align-self: center;
  }
`;
ActionButton.displayName = "ActionButton";

export const DeviceAlertTemplateFieldContainer = styled(Grid)`
  padding: 0 5px;
  width: 120px;
`;
DeviceAlertTemplateFieldContainer.displayName =
  "DeviceAlertTemplateFieldContainer";

export const DeviceAlertTemplateFieldContainerValue = styled(
  DeviceAlertTemplateFieldContainer
)`
  width: auto;
  flex: 1;
`;
DeviceAlertTemplateFieldContainerValue.displayName =
  "DeviceAlertTemplateFieldContainerValue";
export const PulseIn = keyframes`
  0% {
    transform: scale(0.7, 0.7);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: none;
  }
`;

export const AnimatedGrid = styled(Grid)`
  animation-name: ${PulseIn};
  animation-duration: 0.3s;
`;
AnimatedGrid.displayName = "AnimatedGrid";

export const StyledButton = styled(Button)`
  && {
    font-size: 10px;
    :hover {
      background: white;
      cursor: default;
    }
  }
`;
StyledButton.displayName = "StyledButton";

interface IDeviceAlertTemplateFormArrayProps {
  deviceAlertNotificationKind: (index: number) => void;
  sendDeviceAlertTestMessage: (phone: string) => void;
}

export type DeviceAlertTemplateFormArrayType = FieldArrayRenderProps &
  IDeviceAlertTemplateFormArrayProps;

export const DeviceAlertTemplateFormArray: React.FunctionComponent<DeviceAlertTemplateFormArrayType> = ({
  form,
  push,
  remove,
  deviceAlertNotificationKind,
  sendDeviceAlertTestMessage
}: DeviceAlertTemplateFormArrayType) => {
  const {
    editUserProfile: { email },
    editDeviceAlert: { kind, phone }
  } = Validators;
  const {
    alert: { notificationTypes }
  } = Configuration;

  const {
    values: { notification: notifications, isLoading, notificationKind },
    isSubmitting,
    setFieldValue,
    handleBlur
  } = form;

  const removeNotification = (index: number) => (_: React.MouseEvent) => {
    deviceAlertNotificationKind(index);
    remove(index);
    clearInterval(notifications[index].timer.timerId);

    notifications
      .filter((__: INotification, itemIndex: number) => itemIndex !== index)
      .forEach(
        (
          { timer: { count, timerId } }: INotification,
          notificationIndex: number
        ) => {
          if (count > 0) {
            clearInterval(timerId);
            handleNotificationTimerStart(notificationIndex, count);
          }
        }
      );
  };

  const addProperty = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      kind: "",
      defaultMessage: "",
      countryCode: getUserLocalePhoneCode().code,
      mobileNumber: "",
      emailAddress: "",
      timer: { count: 0, running: false }
    });
  };

  const handleKindChange = (index: number) => ({
    target: { name, value }
  }: React.ChangeEvent<{ name: string; value: string }>) => {
    setFieldValue(name, value);

    if (notificationKind[index] !== value) {
      notifications[index].mobileNumber = "";
      notifications[index].emailAddress = "";
      notificationKind[index] = value;

      if (notifications[index].timer.count > 0) {
        clearInterval(notifications[index].timer.timerId);
        setFieldValue(`notification[${index}].timer.running`, false);
        setFieldValue(`notification[${index}].timer.count`, 0);
      }
    }
  };
  const renderPhoneCode = (code: string) => `+${code}`;
  const trimMobile = (index: number) => (e: React.SyntheticEvent) => {
    setFieldValue(
      `notification[${index}].mobileNumber`,
      trimPhoneNumber(notifications[index].mobileNumber)
    );
    handleBlur(e);
  };

  const sendTestMessageHandle = (index: number) => (_: React.MouseEvent) => {
    if (
      phone(notifications[index].countryCode)(
        notifications[index].mobileNumber
      ) === null
    ) {
      sendDeviceAlertTestMessage(
        notifications[index].countryCode + notifications[index].mobileNumber
      );

      handleNotificationTimerStart(index);
    }
  };

  const handleNotificationTimerStart = (index: number, currentTimerCount?: number) => {
    notifications[index].timer.count = currentTimerCount || 59;
    setFieldValue(`notification[${index}].timer.running`, true);
    const timerID = setInterval(() => {
      const timerCount = (count: number) => {
        if (count > 9) {
          return count;
        } else if (count > 0) {
          return "0" + count;
        } else {
          return "0" + 0;
        }
      };

      if (notifications[index].timer.count > 0) {
        const newCount = notifications[index].timer.count - 1;
        notifications[index].timer.count = newCount >= 0 ? newCount : 0;

        setFieldValue(
          `notification[${index}].timer.count`,
          timerCount(newCount)
        );
      } else {
        clearInterval(timerID);
        setFieldValue(`notification[${index}].timer.running`, false);
      }
    }, 1000);
    setFieldValue(`notification[${index}].timer.timerId`, timerID);
  };

  return (
    <React.Fragment>
      <Grid container={true} item={true} justify="flex-start">
        {notifications.map((notification: INotification, index: number) => (
          <AnimatedGrid
            item={true}
            container={true}
            direction="row"
            xs={12}
            alignItems="center"
            key={index}
          >
            <Grid
              item={true}
              container={true}
              direction="row"
              alignItems="baseline"
            >
              <DeviceAlertTemplateFieldContainer>
                <StyledSelect>
                  <InputLabel htmlFor={`notification[${index}].kind`}>
                    Method
                  </InputLabel>
                  <Field
                    name={`notification[${index}].kind`}
                    label="Method"
                    component={Select}
                    disabled={isSubmitting || isLoading}
                    validate={kind}
                    onChange={handleKindChange(index)}
                  >
                    {notificationTypes.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                </StyledSelect>
              </DeviceAlertTemplateFieldContainer>
              <DeviceAlertTemplateFieldContainerValue item={true}>
                {notificationKind[index] === "SMS" && (
                  <StyledTextField
                    type="text"
                    label="Mobile"
                    name={`notification[${index}].mobileNumber`}
                    component={TextField}
                    disabled={isSubmitting || isLoading}
                    onBlur={trimMobile(index)}
                    validate={phone(notifications[index].countryCode)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormControl>
                            <Field
                              name={`notification[${index}].countryCode`}
                              component={Select}
                              disabled={isSubmitting || isLoading}
                              renderValue={renderPhoneCode}
                            >
                              {countryCodes.map((country: ICountryCode) => (
                                <MenuItem
                                  key={country.iso}
                                  value={country.code}
                                >
                                  <span>{country.country}&nbsp;</span>
                                  <GreyPhoneCode>+{country.code}</GreyPhoneCode>
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                {notificationKind[index] === "EMAIL" && (
                  <StyledTextField
                    type="text"
                    label="Email"
                    name={`notification[${index}].emailAddress`}
                    component={TextField}
                    disabled={isSubmitting || isLoading}
                    validate={email}
                  />
                )}
                {!notificationKind[index] && (
                  <StyledTextField
                    type="text"
                    label="Disabled"
                    name={`notification[${index}].emailAddress`}
                    component={TextField}
                    disabled={true}
                  />
                )}
              </DeviceAlertTemplateFieldContainerValue>

              {notificationKind[index] === "SMS" && (
                <DeviceAlertTestSMSMessage
                  alert={form.values}
                  index={index}
                  isSubmitting={isSubmitting}
                  sendTestMessageHandle={sendTestMessageHandle}
                />
              )}
              {!(isSubmitting || isLoading) && (
                <Tooltip title="Delete">
                  <ActionButton
                    visibility="visible"
                    onClick={removeNotification(index)}
                    aria-label="Delete"
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              )}
            </Grid>
          </AnimatedGrid>
        ))}
        <IconButton onClick={addProperty} disabled={isSubmitting || isLoading}>
          <AddIcon />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
};
DeviceAlertTemplateFormArray.displayName = "DeviceAlertTemplateFormArray";
