import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

export interface IEveDeleteAction {
  id: string;
  etag: string;
}

export interface IDeleteDialog {
  deleteAction: (id: string) => Promise<any>;
  item: string;
  iconButton?: boolean;
}

export const DeleteDialog: React.FunctionComponent<IDeleteDialog> = ({
  deleteAction,
  item,
  iconButton,
  children
}) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteItem = async () => {
    setLoading(true);
    await deleteAction(item);
    setLoading(false);
    handleClose();
  };

  return (
    <React.Fragment>
      {iconButton ? (
        <IconButton color="primary" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      ) : (
        <Button color="primary" onClick={handleClickOpen}>
          Delete <DeleteIcon />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          This action cannot be undone
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            disabled={isLoading}
            autoFocus={true}
          >
            No
          </Button>
          <Button onClick={deleteItem} color="primary" disabled={isLoading}>
            Yes
          </Button>
        </DialogActions>
        {isLoading && <LinearProgress />}
      </Dialog>
    </React.Fragment>
  );
};
