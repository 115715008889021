const APIBaseURL = "https://api.herames.net";

export const DevelopmentConfig = {
  APIBaseURL,
  EdiAPIUrl:
    "https://zgw860m7j4.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edi",
  EsmaAPIUrl:
    "https://x99oimnc68.execute-api.ap-southeast-2.amazonaws.com/dev/v1/esma",
  EaraAPIUrl:
    "https://sqwz2mbi39.execute-api.ap-southeast-2.amazonaws.com/dev/v1/eara",
  EdnaAPIUrl: APIBaseURL + "/v1/edna",
  ExportAPIUrl: APIBaseURL + "/exports",
  ElaAPIUrl:
    "https://jrfuzsvp18.execute-api.ap-southeast-2.amazonaws.com/dev/v1/ela",
  EdlaAPIUrl:
    "https://n61sxr4i89.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edla",

  ediApikey: "3HUp0NiyTLpslBkrhVEt7pNMvh9oSul4WGKgbrl4",
  esmaApikey: "wuO5LFbN7c7HyiKMStpAO2AdyaqYJv9mDduvz6Z0",
  eramaApikey: "DpIUFJ7KpzaDr96kIedRM440mRJq2DTO9L0e9hQq",
  earaApikey: "Si3lOjgnsnQkAtg31SusPKT7rl3t7E1QKV1o28Al",
  authApikey: "J6i8kwTLcfamIeC2aXZOc5oBcU19yjYQuyZ8nBnc",
  ednaApikey: "Qui5oocapuX0aer4ucuJee3GOsho5fe6noo8naeN",
  elaApikey: "xZcGQ2Y9zVhgdkASKDYdp6K3fFL8hDcevrwgwxtZ",
  edlaApikey: "dWEHP9LUw6tp7XW4pzWdShMh9mfE9J0dvtK9qjRK",

  auth: {
    cognitoRoot: "https://nimausers.auth.ap-southeast-2.amazoncognito.com/",
    tokenUrl:
      "https://r2fypacxjf.execute-api.ap-southeast-2.amazonaws.com/dev/v1/auth/token",
    login: "login",
    logout: "logout",
    redirectUri: "http://localhost:3000/",
    clientId: "56475e2uadjv4jae48ls3p5gdg"
  },
  development: true,
  geo: {
    mapboxKey:
      "pk.eyJ1IjoiZXZ0LXZsYWQiLCJhIjoiY2s2MXlheTB3MDd1NzNkbzQwbXQ5OHJ3cSJ9.F0gdRvZRB0aTo1B2tf4pJg",
    googleMapsKey: "AIzaSyBXvStDcNmaOrP3peTcX9lfF0TGIv2hYDI",
    trips: {
      splitTripTimeout: 15 * 60 * 1000,
      trailLength: 1000,
      animationSpeedMultiplier: 1 * 10000,
      iconSizeMultiplier: 3,
      pathWidth: { min: 3, max: 10 }
    },
    mapCenter: {
      lat: -25.61,
      lng: 134.35472
    },
    defaultZoom: {
      noDevice: 4,
      oneDevice: 15
    },
    clustering: {
      minZoom: 3,
      maxZoom: 25,
      radius: 40
    }
  },
  influxConfig: {
    protocol: "https",
    host: "api.herames.net",
    port: "",
    database: "v1",
    options: {
      headers: {
        "x-api-key": "H3Vz903rbe3cN98Um9jNolLiSgt2HUP2sR5Mo1th",
        "x-lnx-token": ""
      },
      hostname: "api.herames.net/v1/influxdb"
    }
  }
};
