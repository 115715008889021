import {
  ICurrentUser,
  IGlobalState,
  initialGlobalState,
  IResourceLink
} from "../models/IGlobalState";
import { ActionType } from "../core/ActionTypes";

export type globalStateReducerTypes =
  | { type: ActionType.OPEN_MENU_CHANGE; payload: boolean }
  | { type: ActionType.LOGIN_ERROR }
  | { type: ActionType.GET_RESOURCES; payload: IResourceLink[] }
  | { type: ActionType.GET_CONNECTED_DEVICES; payload: number }
  | { type: ActionType.AUTHENTICATION; payload: ICurrentUser }
  | { type: ActionType.TOOLBAR_MENU_CHANGE_STATE; payload: any }
  | { type: ActionType.REFRESH_REQUIRED }
  | { type: ActionType.CLIENT_UPDATE }
  | { type: ActionType.LOGOUT }
  | { type: ActionType.NO_HEADER }
  | { type: ActionType.SESSION_EXPIRED }
  | { type: ActionType.REINITIALIZE }
  | { type: ActionType.USER_PROFILE_LOADING; payload: boolean };

export function globalStateReducer(
  state: IGlobalState = { ...initialGlobalState },
  action: globalStateReducerTypes
): IGlobalState {
  switch (action.type) {
    case ActionType.OPEN_MENU_CHANGE:
      state = {
        ...state,
        menuOpen: action.payload
      };
      break;
    case ActionType.NO_HEADER:
      state = {
        ...state,
        noHeader: true,
        menuOpen: false
      };
      break;
    case ActionType.SESSION_EXPIRED:
      state = {
        ...state,
        sessionExpired: true
      };
      break;
    case ActionType.LOGIN_ERROR:
      state = {
        ...state,
        loginError: true
      };
      break;
    case ActionType.GET_RESOURCES:
      state = {
        ...state,
        resources: action.payload,
        resourcesLoaded: true
      };
      break;
    case ActionType.REINITIALIZE:
      state = {
        ...state,
        resources: [],
        menuDisabled: true
      };
      break;
    case ActionType.USER_PROFILE_LOADING:
      state = {
        ...state,
        menuDisabled: !action.payload ? false : state.menuDisabled
      };
      break;
    case ActionType.GET_CONNECTED_DEVICES:
      state = {
        ...state,
        devicesConnected: action.payload
      };
      break;
    case ActionType.AUTHENTICATION:
      state = {
        ...state,
        user: action.payload
      };
      break;
    case ActionType.TOOLBAR_MENU_CHANGE_STATE:
      state = {
        ...state,
        toolbarMenuOpen: action.payload
      };
      break;
    case ActionType.REFRESH_REQUIRED:
      state = {
        ...state,
        refreshRequired: true
      };
      break;
    case ActionType.CLIENT_UPDATE:
      state = {
        ...state,
        refreshRequired: false
      };
      break;
    default:
      break;
  }
  return state;
}
