import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import { Snackbar, Button, Grid, Hidden } from "@material-ui/core";
import { NavigateBefore as BackIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider, theme } from "../core/withThemeProvider";
import { Header } from "../components/Header";
import {
  ICurrentUser,
  IGlobalState,
  IResourceLink
} from "../models/IGlobalState";
import { Menu } from "../components/Menu";
import { IRootState } from "../core/store";
import {
  logIn,
  logOut,
  openMenuAction,
  toggleToolbarMenuState,
  updateClient
} from "../actions/globalStateAction";
import { Configuration } from "../core/configuration/config";
import { StyledToolbarIndent } from "../components/sharedStyledComponents";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { getResources } from "../actions/globalStateAction";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { getUserById } from "../Profile/actions/userProfileAction";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";
import { getSubscriptionAndRoleName } from "../Profile/services/UserProfileService";

export const RootContent = styled.div`
  position: relative;
  padding-bottom: 34px;
  flex-grow: 1;
  background-color: ${theme.palette.background.default};
  transition-duration: ${theme.transitions.duration.leavingScreen}ms;
  transition-timing-function: ${theme.transitions.easing.sharp};
  &[data-open="true"] {
    ${theme.breakpoints.up("md")} {
      margin-left: ${Configuration.menuWidth}px;
      transition-property: margin-left;
      transition-duration: ${theme.transitions.duration.enteringScreen}ms;
      transition-timing-function: ${theme.transitions.easing.easeOut};
    }
  }
`;
RootContent.displayName = "RootContent";

export const StyledGrid = styled(Grid)`
  padding: 0 24px;
`;
StyledGrid.displayName = "StyledGrid";

export const Footer = styled.footer`
  background: ${() => theme.palette.secondary.main};
  font-size: 14px;
  text-align: center;
  color: ${() => theme.palette.secondary.contrastText};
  padding: 7px;
  position: fixed;
  bottom: 0;
  z-index: ${theme.zIndex.drawer + 1};
  width: 100%;
  & > a {
    color: inherit;
  }
`;
Footer.displayName = "Footer";

export const BackButton = styled(Button)`
  && {
    text-transform: capitalize;
  }
`;
BackButton.displayName = "BackButton";

interface IStateProps {
  globalState: IGlobalState;
  profile: IUserProfile;
}

interface IDispatchProps {
  openMenu: (currentState: boolean) => void;
  updateClient: () => void;
  fetchResources: () => Promise<IResourceLink[] | SnackbarError>;
  getUser: (id: string) => Promise<IUserProfile | SnackbarError>;
  signIn: (query: string) => Promise<ICurrentUser | SnackbarError>;
  logout: () => void;
  toggleToolbarMenu: (toolbarMenuState: Element | null) => void;
}

export type RootProps = RouteComponentProps &
  IStateProps &
  IDispatchProps &
  WithSnackbarProps;

export class Root extends React.Component<RootProps> {
  public async componentDidMount() {
    const { signIn, fetchResources, getUser, enqueueSnackbar } = this.props;

    const user = await signIn(this.props.location.search);
    if (user && !isSnackbarError(user)) {
      const profile = await getUser(user.userId);
      if (isSnackbarError(profile)) {
        enqueueSnackbar(profile.message, profile.options);
      }
      const resources = await fetchResources();
      if (isSnackbarError(resources)) {
        enqueueSnackbar(resources.message, resources.options);
      }
    }
  }

  public showToolbarMenu = (e: any) => {
    this.props.toggleToolbarMenu(e.target);
  };

  public closeToolbarMenu = () => {
    this.props.toggleToolbarMenu(null);
  };

  public showMenu = (): void => {
    this.props.openMenu(this.props.globalState.menuOpen);
  };

  public refreshClient = (): void => {
    this.props.updateClient();
  };

  public render() {
    const {
      globalState: {
        toolbarMenuOpen,
        user,
        refreshRequired,
        resources,
        noHeader,
        menuOpen,
        resourcesLoaded,
        loginError,
        sessionExpired,
        menuDisabled
      },
      location: { pathname },
      history: { goBack },
      children,
      logout,
      profile
    } = this.props;
    const { subscription, role } = getSubscriptionAndRoleName(
      profile.hasRoles,
      profile.activeSubscription,
      profile.activeRole
    );
    return (
      <React.Fragment>
        {!noHeader && (
          <Header
            toolbarMenuOpen={toolbarMenuOpen}
            user={profile.name}
            userId={profile.shortId}
            subName={subscription}
            roleName={role}
            logout={logout}
            menuClick={this.showMenu}
            handleClose={this.closeToolbarMenu}
            handleOpen={this.showToolbarMenu}
          />
        )}
        {user && (
          <React.Fragment>
            <Menu
              resources={resources}
              resourcesLoaded={resourcesLoaded}
              userId={user.userId}
              closeMenu={this.showMenu}
              menuOpen={menuOpen}
              disabled={menuDisabled}
            />

            {!noHeader && <StyledToolbarIndent />}
            <RootContent data-open={menuOpen}>
              <Hidden mdUp={true}>
                {pathname.split("/").filter(path => path.length > 0).length >
                  1 && (
                  <BackButton color="primary" onClick={goBack}>
                    <BackIcon />
                    Back
                  </BackButton>
                )}
              </Hidden>
              <Breadcrumbs />
              <StyledGrid
                container={true}
                item={true}
                direction="column"
                alignItems="stretch"
                xs={12}
                zeroMinWidth={true}
              >
                {children}
              </StyledGrid>
            </RootContent>
          </React.Fragment>
        )}
        {!noHeader && !Configuration.hideFooter && (
          <Footer>
            Powered by <a href="https://ellenex.com">ellenex.com</a> - All
            Rights Reserved.
          </Footer>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginError}
          ContentProps={{ "aria-describedby": "message-id" }}
          onClose={logout}
          message={
            <span id="message-id">
              User access denied. Please, contact support.
            </span>
          }
          action={
            <Button key="OK" aria-label="OK" color="inherit" onClick={logout}>
              OK
            </Button>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={sessionExpired}
          ContentProps={{ "aria-describedby": "session-expired" }}
          onClose={logout}
          message={
            <span id="session-expired">
              Your session has expired. Please, sign in again!
            </span>
          }
          action={
            <Button
              key="signIn"
              aria-label="signIn"
              color="inherit"
              onClick={logout}
            >
              Sign In
            </Button>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={refreshRequired}
          ContentProps={{ "aria-describedby": "message-id" }}
          onClose={this.refreshClient}
          message={<span id="message-id">New version available!</span>}
          action={
            <Button
              key="update"
              aria-label="Update"
              color="inherit"
              onClick={this.refreshClient}
            >
              Refresh
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  profile: state.profile,
  globalState: state.globalState
});

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    getUser: (userId: string) => dispatch(getUserById(userId)),
    openMenu: (currentState: boolean) => {
      dispatch(openMenuAction(currentState));
    },
    updateClient: () => {
      dispatch(updateClient());
    },
    fetchResources: () => dispatch(getResources()),
    signIn: (query: string) => dispatch(logIn(query)),
    logout: () => {
      dispatch(logOut());
    },
    toggleToolbarMenu: (toolbarMenuState: Element | null) => {
      dispatch(toggleToolbarMenuState(toolbarMenuState));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withThemeProvider(withSnackbar(Root)))
);
