import * as React from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";

import { DevicePin } from "./Pin";
import { Configuration } from "../../core/configuration/config";
import { IGeolocation, initialLocations } from "../models/IGeolocation";
import { IDevice } from "../../Devices/models/IDevice";
import { getDeviceLocation } from "../services/LocationService";
import { DeviceClusteringMap } from "./DeviceClusteringMap";

export const DeviceMapBounds = styled.div`
  height: 400px;
  width: 100%;
`;
DeviceMapBounds.displayName = "DeviceMapBounds";

interface IDeviceMapProps {
  device: IDevice;
  mapType?: string;
}

export const DeviceMap: React.FunctionComponent<IDeviceMapProps> = (
  props: IDeviceMapProps
) => {
  const { device, mapType } = props;
  let location = { ...initialLocations };
  if (device) {
    location = getDeviceLocation(device);
    location.isLoading = false;
  }
  return (
    <DeviceMapBounds>
      {location.isLoading ? (
        <DeviceClusteringMap mapType={mapType} typeControl={true} />
      ) : (
        <GoogleMapReact
          options={{
            mapTypeId: mapType ? mapType : "roadmap",
            mapTypeControl: true
          }}
          bootstrapURLKeys={{ key: Configuration.geo.googleMapsKey }}
          zoom={device.id ? location.zoom + 2 : location.zoom}
          center={location.center}
        >
          {location.locations.map((loc: IGeolocation, i: number) => (
            <DevicePin key={i} lat={loc.lat} lng={loc.lng} />
          ))}
        </GoogleMapReact>
      )}
    </DeviceMapBounds>
  );
};
DeviceMap.displayName = "DeviceMap";
