import React from "react";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";

import { IDownlinkTableProps } from "../DownlinkTable";
import { Pagination } from "../../../../components/Pagination";

export const DeviceDownlinkTableFooter: React.FunctionComponent<IDownlinkTableProps> = ({
  totalItems,
  onChangePage,
  pagination
}: IDownlinkTableProps) => {
  return (
    <TableFooter>
      {pagination && (
        <TableRow>
          <TableCell>
            <Pagination
              totalItems={totalItems}
              onChangePage={onChangePage}
              pagination={pagination}
            />
          </TableCell>
        </TableRow>
      )}
    </TableFooter>
  );
};
