// @ts-ignore
import { fitBounds } from "google-map-react/utils";
import moment from "moment";
import { Feature, FeatureCollection, Point, LineString } from "geojson";
import { IDevice, IDeviceCollection } from "../../Devices/models/IDevice";
import {
  IGeolocation,
  ILocation,
  initialLocations
} from "../models/IGeolocation";
import { Configuration } from "../../core/configuration/config";
import { IInfluxTableQuery } from "../../Resources/models/IResource";
import {
  IGeoJSONPathProperties,
  IGeoJSONPointProperties,
  ILocationHistoryAPI,
  ILocationHistoryCollectionAPI,
  ILocationsHistory,
  ILocationsHistoryQuery,
  initialLocationsHistory
} from "../models/ILocationsHistory";
import { getShortId } from "../../core/utilities/ServiceUtilities";
import { ScatterplotLayer, PathLayer, IconLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { IDeckGLTooltip } from "../components/TripsMap";
import { frames } from "../models/airtec-map-sprite.json";

export const getDeviceLocation = (device: IDevice): ILocation => {
  const extractedLocations = extractDeviceLocation(device);
  const map: ILocation = {
    ...initialLocations
  };
  if (extractedLocations) {
    map.center = extractedLocations;
    map.locations = [extractedLocations];
    map.zoom = Configuration.geo.defaultZoom.oneDevice;
  }
  return map;
};

export const getDeviceCollectionLocation = (
  collection: IDeviceCollection,
  height: number,
  width: number
): ILocation => {
  const extractedLocations = extractCollectionLocations(collection);
  const map: ILocation = {
    ...initialLocations
  };
  if (extractedLocations) {
    map.locations = extractCollectionLocations(collection);
    const bounds = getBounds(map.locations, height, width);
    map.center = bounds.center;
    map.zoom = bounds.zoom;
  }
  return map;
};

export const generateDeviceDataPointString = (
  measurement?: IInfluxTableQuery
): string => {
  const { defaultDateFormat, defaultTimeFormat } = Configuration;
  let deviceMeasurement = "";
  if (!measurement) {
    return "";
  }
  if (measurement.primaryParameter?.value) {
    deviceMeasurement = measurement.primaryParameter.value.toString();
    if (measurement.primaryParameter.unitText) {
      deviceMeasurement += ` ${measurement.primaryParameter.unitText}`;
    }
  }
  if (measurement.lastSeen) {
    deviceMeasurement += ` @ ${measurement.lastSeen.format(
      defaultDateFormat + " " + defaultTimeFormat
    )}`;
  }
  return deviceMeasurement;
};

export const extractDeviceLocation = (
  device: IDevice
): IGeolocation | undefined => {
  if (
    device.autoLocation &&
    device.autoLocation.geo.latitude &&
    device.autoLocation.geo.longitude &&
    device.autoLocation.geo.latitude.length > 0 &&
    device.autoLocation.geo.longitude.length > 0
  ) {
    return {
      lat: parseFloat(device.autoLocation.geo.latitude),
      lng: parseFloat(device.autoLocation.geo.longitude),
      id: device.shortId,
      name: device.name,
      measurement: generateDeviceDataPointString(device.measurements.values)
    };
  }
  if (
    device.manualLocation &&
    device.manualLocation.geo.latitude &&
    device.manualLocation.geo.longitude &&
    device.manualLocation.geo.latitude.length > 0 &&
    device.manualLocation.geo.longitude.length > 0
  ) {
    return {
      lat: parseFloat(device.manualLocation.geo.latitude),
      lng: parseFloat(device.manualLocation.geo.longitude),
      id: device.shortId,
      name: device.name,
      measurement: generateDeviceDataPointString(device.measurements.values),
      primaryParameter: device.measurements.primaryParameter
    };
  }
};

export const extractCollectionLocations = (
  collection: IDeviceCollection
): IGeolocation[] => {
  return collection.members
    .map((device: IDevice) => extractDeviceLocation(device))
    .filter((location?: IGeolocation) => !!location) as IGeolocation[];
};

export const getBounds = (
  locations: IGeolocation[],
  height?: number,
  width?: number
) => {
  const { defaultZoom, mapCenter } = Configuration.geo;
  if (!width || !height) {
    throw new TypeError(
      "Width and Height of container are required for device collection"
    );
  }
  let center = mapCenter;
  let zoom = defaultZoom.noDevice;
  if (locations.length === 1) {
    center = locations[0];
    zoom = defaultZoom.oneDevice;
  }
  if (locations.length > 1) {
    const latArray: number[] = locations.map(location => location.lat);
    const lngArray: number[] = locations.map(location => location.lng);

    const bounds = {
      ne: {
        lat: Math.max(...latArray),
        lng: Math.max(...lngArray)
      },
      sw: {
        lat: Math.min(...latArray),
        lng: Math.min(...lngArray)
      }
    };
    if (bounds.ne.lat === bounds.sw.lat && bounds.ne.lng === bounds.sw.lng) {
      center = bounds.ne;
      zoom = defaultZoom.oneDevice;
      return { center, zoom };
    }
    const fittedBounds = fitBounds(bounds, { width, height });
    fittedBounds.zoom -= 1;
    return fittedBounds;
  }
  return { center, zoom };
};

export const locationHistoryCollectionFromApi = (
  collection: ILocationHistoryCollectionAPI,
  { from, to, timeout, radius }: ILocationsHistoryQuery,
  longitude: number,
  latitude: number,
  zoom: number
): ILocationsHistory => {
  const { device, member, view, totalItems } = collection;
  return {
    isLoading: true,
    id: collection["@id"],
    type: collection["@type"],
    deviceId: device,
    deviceShortId: getShortId(device),
    devices: [],
    points: {
      type: "FeatureCollection",
      features: setPoints(member)
    },
    trips: {
      type: "FeatureCollection",
      features: setTrips(member)
    },
    totalItems,
    view,
    shortId: getShortId(collection["@id"]),
    from,
    to,
    timeout,
    radius,
    sourceObject: collection,
    viewport: {
      ...initialLocationsHistory.viewport,
      latitude,
      longitude,
      zoom
    }
  };
};

export const setPoints = (
  points: ILocationHistoryAPI[]
): Array<Feature<Point, IGeoJSONPointProperties>> => {
  return points.map(
    (point): Feature<Point, IGeoJSONPointProperties> => {
      return {
        type: "Feature",
        geometry: { coordinates: point.geometry.coordinates, type: "Point" },
        properties: { time: point.time }
      };
    }
  );
};

export const setTrips = (
  geoJSON: ILocationHistoryAPI[]
): Array<Feature<LineString, IGeoJSONPathProperties>> => {
  const trips: Array<Feature<LineString, IGeoJSONPathProperties>> = [];
  geoJSON
    .filter(point => point.geometry.coordinates.length > 1 && point.time)
    .sort(
      (oldPoint, newPoint) =>
        new Date(oldPoint.time).valueOf() - new Date(newPoint.time).valueOf()
    )
    .map((point, index, arr) => {
      let time = new Date(point.time).valueOf();
      if (index === 0) {
        time = 0;
      } else {
        time = time - new Date(arr[0].time).valueOf();
      }
      return {
        ...point,
        time
      };
    })
    .forEach((point, index, array) => {
      if (index === 0) {
        trips.push({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [point.geometry.coordinates]
          },
          properties: {
            time: [point.time]
          }
        });
      } else {
        if (
          point.time >
          array[index - 1].time + Configuration.geo.trips.splitTripTimeout
        ) {
          trips.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: []
            },
            properties: {
              time: []
            }
          });
        }
        trips[trips.length - 1].properties.time.push(point.time);
        trips[trips.length - 1].geometry.coordinates.push(
          point.geometry.coordinates
        );
      }
    });
  return trips;
};

export const getMaxTime = (
  geoJSON: FeatureCollection<LineString, IGeoJSONPathProperties>
): number => {
  const lastFeature = geoJSON.features[geoJSON.features.length - 1];
  if (lastFeature) {
    return (
      lastFeature.properties.time[lastFeature.properties.time.length - 1] ||
      Infinity
    );
  }
  return Infinity;
};

export const getTripsLayer = (
  geoJSON: FeatureCollection<LineString, IGeoJSONPathProperties>,
  frame: number
) => {
  const {
    trailLength,
    animationSpeedMultiplier,
    pathWidth: { max, min }
  } = Configuration.geo.trips;
  return new TripsLayer({
    id: "trips",
    data: geoJSON.features,
    highlightColor: [25, 118, 210] as any,
    widthMinPixels: min,
    widthMaxPixels: max,
    getPath: data =>
      data.geometry.coordinates as Array<[number, number, number]>,
    getTimestamps: (data: Feature<LineString, IGeoJSONPathProperties>) =>
      data.properties.time,
    getColor: (_: any) => [0, 0, 0] as any,
    trailLength: trailLength * animationSpeedMultiplier,
    currentTime: frame
  });
};

export const getPathLayer = (
  geoJSON: FeatureCollection<LineString, IGeoJSONPathProperties>,
  setTooltip: (value: IDeckGLTooltip) => void
) => {
  const {
    pathWidth: { max, min }
  } = Configuration.geo.trips;
  return new PathLayer({
    id: "paths",
    data: geoJSON.features,
    getPath: data =>
      data.geometry.coordinates as Array<[number, number, number]>,
    getColor: (_: unknown) => [100, 181, 246],
    highlightColor: [25, 118, 210] as any,
    widthMinPixels: min,
    widthMaxPixels: max,
    pickable: true,
    autoHighlight: true,
    onHover: ({ object, x, y }) => {
      setTooltip({
        item: object,
        x,
        y,
        message: "Trip X"
      });
    }
  });
};

export const getScatterplotLayer = (
  geoJSON: FeatureCollection<Point, IGeoJSONPointProperties>,
  setTooltip: (value: IDeckGLTooltip) => void
) => {
  return new ScatterplotLayer({
    id: "points",
    data: geoJSON.features,
    pickable: true,
    filled: true,
    radiusMaxPixels: 11,
    radiusMinPixels: 3,
    lineWidthMinPixels: 1,
    getPosition: (point: Feature<Point, IGeoJSONPointProperties>) =>
      point.geometry.coordinates as [number, number],
    getFillColor: (_: unknown) => [33, 150, 243],
    onHover: ({ object, x, y }) => {
      let message = "";
      if (object) {
        message = moment(
          (object as Feature<Point, IGeoJSONPointProperties>).properties.time
        ).format("lll");
      }
      setTooltip({
        x,
        y,
        item: object,
        message
      });
    }
  });
};

export const getPathBeginningIconLayer = (
  geoJSON: FeatureCollection<LineString, IGeoJSONPathProperties>
) => {
  const ICON_MAPPING = {};
  Object.keys(frames).forEach(key => {
    const { x, y, w, h } = frames[key].frame;
    ICON_MAPPING[key] = {
      x,
      y,
      width: w,
      height: h,
      mask: false
    };
  });
  return new IconLayer({
    id: "path-beginning-icons",
    data: geoJSON.features,
    iconAtlas: "/img/airtec-map-sprite.png",
    iconMapping: ICON_MAPPING,
    getIcon: _ => {
      return "start" as any;
    },
    getSize: _ => Configuration.geo.trips.iconSizeMultiplier,
    pickable: false,
    sizeScale: 15,
    getPosition: d => d.geometry.coordinates[0] as any
  });
};

export const locationHistoryCollectionApiToGeolocationArray = (
  collection: ILocationHistoryCollectionAPI
): IGeolocation[] => {
  return collection.member.map(feature => ({
    lat: feature.geometry.coordinates[1],
    lng: feature.geometry.coordinates[0]
  }));
};
