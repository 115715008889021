import styled from "styled-components";
import * as React from "react";
import { LinearProgress, Grid } from "@material-ui/core";

export const NoPaddingTableCell = styled.td`
  padding: 0;
`;
NoPaddingTableCell.displayName = "NoPaddingTableCell";

interface ITableLoadingIndicator {
  colspan: number;
}
export const TableLoadingIndicator: React.FunctionComponent<ITableLoadingIndicator> = props => (
  <tr>
    <NoPaddingTableCell colSpan={props.colspan}>
      <LinearProgress />
    </NoPaddingTableCell>
  </tr>
);
TableLoadingIndicator.displayName = "TableLoadingIndicator";

export const StyledHeaderButtonGrid = styled(Grid)`
  padding: 10px;
`;
StyledHeaderButtonGrid.displayName = "StyledHeaderButtonGrid";
