import React, { useState } from "react";
import GoogleMapReact, { ChangeEventValue } from "google-map-react";
// @ts-ignore
import supercluster from "points-cluster";
import {
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styled from "styled-components";

import { Configuration } from "../../core/configuration/config";
import { ICluster, IGeolocation } from "../models/IGeolocation";
import { DeviceClusterPin, DevicePin } from "./Pin";
import { NoStyleLink } from "../../components/sharedStyledComponents";
import { DeviceIcon } from "./DeviceIcon";

interface IMapProps {
  mapType?: string;
  typeControl?: boolean;
  locations?: IGeolocation[];
}

export const DevicesMapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
DevicesMapContainer.displayName = "DevicesMapContainer";

export const ClusterDetailsContainer = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  color: white;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  z-index: 1;
`;
ClusterDetailsContainer.displayName = "ClusterDetailsContainer";

export const ClusterListContainer = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
  && p {
    color: rgba(255, 255, 255, 0.55);
  }
`;
ClusterListContainer.displayName = "ClusterListContainer";

export const CloseButtonContainer = styled(Grid)`
  position: sticky;
  padding-left: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  && > * {
    color: #fff;
  }
`;
CloseButtonContainer.displayName = "CloseButtonContainer";

export const DevicesBarTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 1.1rem;
  }
`;
DevicesBarTitle.displayName = "DevicesBarTitle";

export const DeviceClusteringMap: React.FunctionComponent<IMapProps> = ({
  mapType,
  typeControl,
  locations = []
}) => {
  const [clusters, setClusters] = useState([]);
  const [activeCluster, setCluster] = useState(undefined as
    | ICluster
    | undefined);
  const updateClusters = (value: ChangeEventValue) => {
    const { maxZoom, minZoom, radius } = Configuration.geo.clustering;
    setClusters(supercluster(locations, { minZoom, maxZoom, radius })(value));
  };
  const onClusterClick = (value?: ICluster) => (mouseEvent: MouseEvent) => {
    mouseEvent.preventDefault();
    if (!activeCluster || activeCluster !== value) {
      setCluster(value);
    } else {
      setCluster(undefined);
    }
  };
  const onClusterClose = () => {
    setCluster(undefined);
  };
  return (
    <DevicesMapContainer>
      {activeCluster && (
        <ClusterDetailsContainer>
          <CloseButtonContainer
            container={true}
            alignItems="center"
            justify="space-between"
          >
            <DevicesBarTitle variant="subtitle1">Devices</DevicesBarTitle>
            <IconButton onClick={onClusterClose}>
              <Close />
            </IconButton>
          </CloseButtonContainer>
          <ClusterListContainer>
            <List>
              {activeCluster.points.map(point => {
                return (
                  <NoStyleLink to={`/devices/${point.id}`} key={point.id}>
                    <ListItem button={true}>
                      <ListItemIcon>
                        <DeviceIcon parameter={point.primaryParameter} />
                      </ListItemIcon>
                      <ListItemText
                        primary={point.name}
                        secondary={point.measurement}
                      />
                    </ListItem>
                  </NoStyleLink>
                );
              })}
            </List>
          </ClusterListContainer>
        </ClusterDetailsContainer>
      )}
      <GoogleMapReact
        options={{
          mapTypeId: mapType ? mapType : "roadmap",
          mapTypeControl: Boolean(typeControl)
        }}
        bootstrapURLKeys={{ key: Configuration.geo.googleMapsKey }}
        onChange={updateClusters}
        zoom={Configuration.geo.defaultZoom.noDevice}
        center={Configuration.geo.mapCenter}
      >
        {clusters.map((cluster: ICluster, index: number) =>
          cluster.numPoints > 1 ? (
            <DeviceClusterPin
              key={index}
              lat={cluster.wy}
              lng={cluster.wx}
              cluster={cluster}
              showCluster={onClusterClick}
            >
              {cluster.numPoints}
            </DeviceClusterPin>
          ) : (
            <DevicePin
              key={index}
              lat={cluster.wy}
              lng={cluster.wx}
              showCluster={onClusterClick}
              cluster={cluster}
            />
          )
        )}
      </GoogleMapReact>
    </DevicesMapContainer>
  );
};
DeviceClusteringMap.displayName = "DeviceClusteringMap";
