import * as React from "react";
import { OptionsObject, useSnackbar, VariantType } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Configuration } from "../configuration/config";
import { store } from "../store";
import { ActionType } from "../ActionTypes";

export enum snackbarMessages {
  ERROR_403 = "You don't have required permission to access ",
  ERROR_404 = "Not Found",
  SERVER_ERROR = "Server Error. Please try again later",
  EXPORT_SUCCESS = "Device data exported successfully",
  PROFILE_UPDATE_SUCCESS = "Profile Updated Successfully",
  DEVICE_UPDATE_SUCCESS = "Device Updated Successfully",
  DEVICE_ALERT_UPDATE_SUCCESS = "Device Alert Updated Successfully",
  DEVICE_ALERT_CREATE_SUCCESS = "Device Alert Created Successfully",
  DEVICE_ALERT_DELETE_SUCCESS = "Device Alert Deleted Successfully",
  DEVICE_COLLECTION_EMPTY = "Devices not found",
  DEVICE_DOWNLINK_CREATE_SUCCESS = "Device Downlink Send Successfully",
  DEVICE_DOWNLINK_DELETE_MISSING_ID = "Downlink cannot be deleted, missing identifier",
  DEVICE_DOWNLINK_DELETED = "Device Downlink Deleted Successfully"
}

interface ISnackbarCloseProps {
  snackbarKey: string | number | undefined;
}

export const SnackbarCloseButton: React.FunctionComponent<ISnackbarCloseProps> = ({
  snackbarKey
}) => {
  const { closeSnackbar } = useSnackbar();
  const close = () => {
    closeSnackbar(snackbarKey);
  };
  return (
    <IconButton key="close" aria-label="Close" color="inherit" onClick={close}>
      <CloseIcon />
    </IconButton>
  );
};
SnackbarCloseButton.displayName = "SnackbarCloseButton";

export const snackbarCloseButtonRenderer = (
  key: string | number | undefined
) => <SnackbarCloseButton snackbarKey={key} />;

export const getStyledSnackbarOptions = (
  variant: VariantType = "default"
): OptionsObject => {
  const { success, error } = Configuration.snackbarAutohideDuration;

  return {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    variant,
    autoHideDuration:
      variant === "warning" || variant === "error" ? error : success,
    action: snackbarCloseButtonRenderer
  };
};

export interface ISnackbarError {
  message: string;
  options: OptionsObject;
}
export type SnackbarError = ISnackbarError | undefined;

export function checkError(err: any): SnackbarError {
  if (!err.response) {
    return {
      message: snackbarMessages.SERVER_ERROR,
      options: getStyledSnackbarOptions("warning")
    };
  } else {
    const {
      response: {
        status,
        request: { responseURL }
      }
    } = err;

    if (status === 401) {
      store.dispatch({
        type: ActionType.SESSION_EXPIRED,
        payload: true
      });
      return;
    } else if (status === 403) {
      return {
        message: snackbarMessages.ERROR_403 + ErrorResource(responseURL),
        options: getStyledSnackbarOptions("warning")
      };
    } else if (status === 404) {
      return {
        message: snackbarMessages.ERROR_404,
        options: getStyledSnackbarOptions("warning")
      };
    } else if (status >= 400 && status <= 499) {
      return {
        message: err.response.data.description,
        options: getStyledSnackbarOptions("warning")
      };
    } else {
      return {
        message: snackbarMessages.SERVER_ERROR,
        options: getStyledSnackbarOptions("warning")
      };
    }
  }
}

export function isSnackbarError<E>(
  response: E | SnackbarError
): response is ISnackbarError {
  return Boolean(response && (response as ISnackbarError).options);
}

export function convertErrorNewLines(message: string): React.ReactNode {
  const messageLinesArray = message.split("\n");

  return (
    <div>
      {messageLinesArray.map((line, index) => (
        <React.Fragment key={index}>
          <span key={"span_" + index}>{line}</span>
          {index < messageLinesArray.length - 1 && <br key={"br_" + index} />}
        </React.Fragment>
      ))}
    </div>
  );
}

export const ErrorResource = (responseURL: string): string =>
  "/" +
  responseURL
    .split("://")[1]
    .split("/")
    .filter((item, index) => index !== 0 && item !== "dev")
    .join("/");
