import moment from "moment";
import * as Influx from "influx";
import { INanoDate } from "influx";
import { IDevice, IParameters } from "../../Devices/models/IDevice";
import {
  IResource,
  IResourceData,
  initialResource,
  IForecast,
  ITableMeasurement
} from "../models/IResource";
import { limitDecimal } from "../../core/utilities/ServiceUtilities";
import { Configuration } from "../../core/configuration/config";
import { IUnits } from "../../core/models/types";

type NullableNumber = number | null;

export interface IInfluxMeasuredProperties {
  device: string;
  "id-air-pressure-pal": NullableNumber;
  "id-air-temperature-cel": NullableNumber;
  "id-battery-level-vlt": NullableNumber;
  "id-water-level-mtr": NullableNumber;
  "id-water-pressure-pal": NullableNumber;
  "id-water-temperature-cel": NullableNumber;
  time: INanoDate;
}

export const getEmptyChart = () => {
  const chart = [];
  for (let i = 7; i >= 0; i--) {
    chart.push({
      max: null as any,
      min: null as any,
      prediction: null as any,
      time: moment()
        .clone()
        .add(3, "month")
        .subtract(i, "month"),
      unit: ""
    });
  }
  return chart;
};

export const getMeasurementUnit = (
  property: string,
  parameters: IParameters[]
): string => {
  if (!parameters) {
    return "";
  }
  const filteredParameter = parameters.filter(
    parameter => parameter.name.toLowerCase() === property.toLowerCase()
  );
  if (filteredParameter.length > 0) {
    return filteredParameter[0].unitText || "";
  }
  return "";
};

export const getFormattedIdList = (devices: IDevice[]): string => {
  const devicesWithId = devices.filter(d => d.shortId !== undefined);
  return devicesWithId
    .map(device => {
      if (device.shortId) {
        return Influx.escape.stringLit(device.shortId);
      }
      return undefined;
    })
    .join(" or device = ");
};

export const mapInfluxData = (resourceData: IResourceData): IResource => {
  const resource = { ...initialResource };
  resource.isLoading = false;
  resource.weatherAvailable = Boolean(
    resourceData.device &&
      resourceData.device.weather.bom &&
      resourceData.device.weather.bom.forecastArea
  );
  if (resourceData) {
    if (resourceData.volumeData) {
      resource.volume = limitDecimal(resourceData.volumeData.volume, 3);
      resource.volumeUnit = resourceData.volumeData.volumeUnit;
    }
    if (resourceData.volumePercentData) {
      resource.volumePercent = limitDecimal(
        resourceData.volumePercentData.volumePercent,
        3
      );
      resource.volumePercentUnit =
        resourceData.volumePercentData.volumePercentUnit;
    }
    if (resourceData.depthData) {
      resource.depth = limitDecimal(resourceData.depthData.depth, 3);
      resource.depthUnit = resourceData.depthData.depthUnit;
    }
    if (resourceData.chart) {
      resource.volumeChanges = resourceData.chart;
    }
    if (resourceData.previousMonthBaseData) {
      resource.pastVolumeOneMonthValue = limitDecimal(
        resourceData.previousMonthBaseData.value,
        3
      );
      resource.pastVolumeOneMonthValueUnit =
        resourceData.previousMonthBaseData.unit;
    }
    if (resourceData.previousThreeMonthsBaseData) {
      resource.pastVolumeThreeMonthValue = limitDecimal(
        resourceData.previousThreeMonthsBaseData.value,
        3
      );
      resource.pastVolumeThreeMonthValueUnit =
        resourceData.previousThreeMonthsBaseData.unit;
    }
    if (resourceData.baseDataSubtYear) {
      resource.volumeLastYear = limitDecimal(
        resourceData.baseDataSubtYear.value,
        3
      );
      resource.volumeLastYearUnit = resourceData.baseDataSubtYear.unit;
    }
    if (resourceData.previousMonthSubtYearBaseData) {
      resource.pastVolumeOneMonthLastYear = limitDecimal(
        resourceData.previousMonthSubtYearBaseData.value,
        3
      );
      resource.pastVolumeOneMonthLastYearUnit =
        resourceData.previousMonthSubtYearBaseData.unit;
    }
    if (resourceData.previousThreeMonthsSubtYearBaseData) {
      resource.pastVolumeThreeMonthLastYear = limitDecimal(
        resourceData.previousThreeMonthsSubtYearBaseData.value,
        3
      );
      resource.pastVolumeThreeMonthLastYearUnit =
        resourceData.previousThreeMonthsSubtYearBaseData.unit;
    }
    if (resourceData.nextMonth) {
      resourceData.nextMonth.forEach(forecast => {
        switch (forecast.chance) {
          case "25":
            resource.nextMonthVolumeChance25 = limitDecimal(forecast.value, 3);
            resource.nextMonthVolumeChance25Unit = forecast.unit;
            break;
          case "50":
            resource.nextMonthVolumeChance50 = limitDecimal(forecast.value, 3);
            resource.nextMonthVolumeChance50Unit = forecast.unit;
            break;
          case "75":
            resource.nextMonthVolumeChance75 = limitDecimal(forecast.value, 3);
            resource.nextMonthVolumeChance75Unit = forecast.unit;
            break;
          default:
            break;
        }
      });
      resource.nextMonthName = setMonthLabels("1m", resourceData.nextMonth);
    }
    if (resourceData.next3Month) {
      resourceData.next3Month.forEach(forecast => {
        switch (forecast.chance) {
          case "25":
            resource.nextThreeMonthVolumeChance25 = limitDecimal(
              forecast.value,
              3
            );
            resource.nextThreeMonthVolumeChance25Unit = forecast.unit;
            break;
          case "50":
            resource.nextThreeMonthVolumeChance50 = limitDecimal(
              forecast.value,
              3
            );
            resource.nextThreeMonthVolumeChance50Unit = forecast.unit;
            break;
          case "75":
            resource.nextThreeMonthVolumeChance75 = limitDecimal(
              forecast.value,
              3
            );
            resource.nextThreeMonthVolumeChance75Unit = forecast.unit;
            break;
          default:
            break;
        }
      });
      resource.nextThreeMonthName = setMonthLabels(
        "3m",
        resourceData.next3Month
      );
    }
    if (resourceData.rainChanceNextMonth) {
      resource.nextMonthRainChance = resourceData.rainChanceNextMonth;
    }
    if (resourceData.rainChanceNext3Month) {
      resource.nextThreeMonthRainChance = resourceData.rainChanceNext3Month;
    }
  }
  return resource;
};

export const growthPercent = (valueOne?: number, valueTwo?: number) => {
  if (valueOne && valueTwo) {
    const value = parseFloat(((valueOne / valueTwo) * 100 - 100).toFixed(2));
    return {
      percent: Math.abs(value),
      rise: value > 0,
      drop: value < 0
    };
  }
  return;
};

export const setMonthLabels = (
  duration: string,
  result?: IForecast[]
): string => {
  const currentTimestamp = moment();
  if (
    result &&
    result.length > 0 &&
    currentTimestamp.isBefore(result[0].time)
  ) {
    const recievedTimestamp = result[0].time;
    return recievedTimestamp.startOf("M").format("MMMM");
  } else {
    switch (duration) {
      case "1m":
        return currentTimestamp
          .startOf("M")
          .add(1, "month")
          .format("MMMM");
      case "3m":
        return currentTimestamp
          .startOf("M")
          .add(3, "month")
          .format("MMMM");
      default:
        return currentTimestamp
          .startOf("M")
          .add(1, "month")
          .format("MMMM");
    }
  }
};

export const getUnitTextByUnitCode = (unitCode: string): string => {
  const unit = Configuration.units
    .filter((unitObject: IUnits) => unitObject.unitCode === unitCode)
    .pop();
  return unit ? unit.unitText : "";
};

export const getDeviceUnitTextByParameterName = (
  parameter: string,
  device?: IDevice
): { unitCode?: string; unitText: string } => {
  if (device?.template.parameters) {
    const requiredParameter = device.template.parameters.filter(
      ({ name }) => name.toLowerCase() === parameter.toLowerCase()
    );
    if (requiredParameter.length === 1) {
      return {
        unitCode: requiredParameter[0].unitCode,
        unitText: requiredParameter[0].unitText
      };
    }
  }
  return { unitText: "" };
};

export const getTablePropertyMeasurement = (
  device: IDevice,
  property: string,
  measurement: number,
  unit: string
): ITableMeasurement => {
  const { unitCode, unitText } = getDeviceUnitTextByParameterName(
    property,
    device
  );
  if (unitCode && unitCode.toLowerCase() === unit.toLowerCase()) {
    return {
      value: measurement,
      unitText,
      unitCode
    };
  }
  return {
    value: measurement,
    unitCode: "",
    unitText: ""
  };
};
