import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikHelpers as FormikActions } from "formik";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { IAddNewDevice, initialAddNewDevice } from "./models/IAddNewDevice";
import { addNewDevice } from "./actions/addNewDeviceAction";
import {
  StyledBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import { AddNewDeviceForm } from "./components/AddNewDeviceForm";
import { IDevice } from "../Devices/models/IDevice";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";

interface IAddNewDeviceDispatchProps {
  addDevice: (newDevice: IAddNewDevice) => Promise<IDevice | SnackbarError>;
}

export class AddNewDevice extends React.Component<
  IAddNewDeviceDispatchProps & WithSnackbarProps
> {
  public onSubmit = async (
    values: IAddNewDevice,
    { setSubmitting, resetForm }: FormikActions<IAddNewDevice>
  ) => {
    const { enqueueSnackbar } = this.props;
    const result = await this.props.addDevice(values);
    if (result) {
      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else {
        resetForm({ values: { ...initialAddNewDevice } });
      }
    }
    setSubmitting(false);
  };
  public render() {
    return (
      <React.Fragment>
        <StyledTitle>ADD NEW DEVICE</StyledTitle>
        <StyledBackground>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initialAddNewDevice }}
            onSubmit={this.onSubmit}
          >
            {props => <AddNewDeviceForm {...props} />}
          </Formik>
        </StyledBackground>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = (dispatch: any): IAddNewDeviceDispatchProps => {
  return {
    addDevice: (newDevice: IAddNewDevice) => dispatch(addNewDevice(newDevice))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(AddNewDevice)));
