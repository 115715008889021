import {
  IApiResponseHeader,
  ICollection,
  IDetermination,
  ILoadable
} from "../../core/models/MixinInterfaces";

import { FeatureCollection, Point, LineString } from "geojson";
import moment, { Moment } from "moment-timezone/moment-timezone";
import { IDevice } from "../../Devices/models/IDevice";
import { FlyToInterpolator, TransitionInterpolator } from "react-map-gl";
import { Configuration } from "../../core/configuration/config";

export interface ILocationsHistoryQuery {
  from: Moment;
  to: Moment;
  radius: string;
  timeout: string;
  deviceId?: string;
  deviceShortId?: string;
}

export type GeoJSONPointCoordinates =
  | [number, number]
  | [number, number, number]; // longitude, latitude, altitude

export interface ILocationHistoryGeometry {
  "@type": string;
  coordinates: GeoJSONPointCoordinates;
}

export interface ILocationHistoryAPI {
  "@type": string;
  time: string;
  geometry: ILocationHistoryGeometry;
}

export interface ILocationHistoryCollectionAPI
  extends IApiResponseHeader,
    ICollection {
  device: string;
  member: ILocationHistoryAPI[];
}

export interface IGeoJSONPointProperties {
  time: string;
}

export interface IGeoJSONPathProperties {
  time: number[];
}

export interface IGeoJSONLineStringProperties {
  timestamps: number[];
}

export interface IDeckGLViewport {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  transitionDuration: number;
  transitionInterpolator: TransitionInterpolator;
}

export interface ILocationsHistory
  extends ILocationsHistoryQuery,
    ILoadable,
    ICollection,
    IDetermination {
  points: FeatureCollection<Point, IGeoJSONPointProperties>;
  trips: FeatureCollection<LineString, IGeoJSONPathProperties>;
  devices: IDevice[];
  sourceObject?: ILocationHistoryCollectionAPI;
  viewport: IDeckGLViewport;
}

export const initialLocationsHistory: ILocationsHistory = {
  isLoading: true,
  id: "",
  type: "",
  shortId: "",
  deviceId: "",
  deviceShortId: "",
  from: moment()
    .startOf("day")
    .subtract(1, "day"),
  to: moment().endOf("day"),
  points: {
    features: [],
    type: "FeatureCollection"
  },
  trips: {
    features: [],
    type: "FeatureCollection"
  },
  radius: "",
  timeout: "",
  view: { "@id": "", "@type": "", first: "", last: "", next: "", previous: "" },
  totalItems: 0,
  devices: [],
  viewport: {
    latitude: Configuration.geo.mapCenter.lat,
    longitude: Configuration.geo.mapCenter.lng,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
    zoom: Configuration.geo.defaultZoom.noDevice
  }
};
